import { ApiResult } from '@typings';
import {
  Device,
  DeviceConnectionHistoryItem,
  UpdateDeviceLoyaltyProgram,
} from '@/components/Fleet/Devices/DevicesPanel/typings';
import apiSlice from '../apiSlice';
import { roleAwareQuery } from '@/redux/api/roleAwareQuery';
import { addDevice } from '@/redux/slices/deviceSlice';

type UpdateDeviceLoyaltyProgramRequest = {
  loyaltyPrograms: string[];
  serialNumber: string;
};

const devicesApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['FleetDevices', 'Fleet', 'Device', 'FleetDevice'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDevices: builder.query<ApiResult<{ items: Device[] }>, void>({
        queryFn: roleAwareQuery('getDevices'),
        providesTags: ['FleetDevices', 'Fleet'],
      }),
      getDevice: builder.query<ApiResult<Device>, string>({
        queryFn: roleAwareQuery('getDevice', (arg) => ({
          params: { serialNumber: arg },
          providesTags: ['FleetDevices', 'Fleet', 'Device'],
        })),
        providesTags: ['FleetDevices', 'FleetDevice', 'Fleet', 'Device'],
        onCacheEntryAdded: async (arg, { dispatch, cacheDataLoaded }) => {
          const result = await cacheDataLoaded;
          dispatch(
            addDevice({
              serialNumber: arg,
              payload: {
                online: result.data?.data.status.connection.online,
                operatingMode: result.data?.data.operatingMode,
                pendingCommands: [],
              },
            })
          );
        },
      }),
      getProtocolVersion: builder.query<ApiResult<{ swVersion: string }>, string>({
        queryFn: roleAwareQuery('getSwVersion', (arg) => ({
          params: { serialNumber: arg },
        })),
      }),
      getDeviceConnectionHistory: builder.query<ApiResult<{ items: DeviceConnectionHistoryItem[] }>, string>({
        queryFn: roleAwareQuery('getDeviceConnectionHistory', (arg) => ({
          params: { serialNumber: arg },
        })),
        providesTags: ['FleetDevices', 'Fleet'],
      }),
      updateDeviceLoyaltyProgram: builder.mutation<
        ApiResult<UpdateDeviceLoyaltyProgram>,
        UpdateDeviceLoyaltyProgramRequest
      >({
        queryFn: roleAwareQuery('patchUpdateLoyaltyPrograms', (arg: UpdateDeviceLoyaltyProgramRequest) => ({
          params: { serialNumber: arg.serialNumber },
          body: { loyaltyPrograms: arg.loyaltyPrograms },
          method: 'PATCH',
        })),
        invalidatesTags: ['FleetDevices'],
      }),
    }),
  });

export const {
  useGetDevicesQuery,
  useGetDeviceQuery,
  useLazyGetDeviceQuery,
  useLazyGetProtocolVersionQuery,
  useGetDeviceConnectionHistoryQuery,
  useUpdateDeviceLoyaltyProgramMutation,
} = devicesApiSlice;

export default devicesApiSlice;
