import { Box, Button, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useGetDeviceIdentityQuery } from '@/redux/api/system/deviceIdentitiesApiSlice';
import useIsTablet from '../../../hooks/useIsTablet';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeviceIdentityPanel from '@/components/OneTools/Ecosystem/DeviceIdentities/DeviceIdentityPanel';
// eslint-disable-next-line max-len
import useDeviceIdentityDialogs from '@/components/OneTools/Ecosystem/DeviceIdentities/DeviceIdentitiesPanel/useDeviceIdentityDialogs';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import Page404 from '@/router/core/404';
import LoadingMessage from '@/components/Shared/withErrorLoadingManagement/LoadingMessage';
import { ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';
import FixedToolbar, { scrolledAmountIsGreaterThan } from '@/components/Shared/Toolbar';

const DeviceIdentity = () => {
  const { t } = useTranslation();
  const { deviceIdentityId } = useParams();
  const navigate = useNavigate();
  const isTablet = useIsTablet();

  const { data, isLoading, isError } = useGetDeviceIdentityQuery(deviceIdentityId!, { skip: !deviceIdentityId });

  const { AddEditDialog, openDialog, ConfirmDialog, confirm, deleteDeviceIdentityFlags } = useDeviceIdentityDialogs();

  useLayoutEffect(() => {
    const beforeUnloadListener = () => {
      return isLoading && <LoadingMessage />;
    };
    window.addEventListener('beforeunload', beforeUnloadListener);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  });

  const DeviceIdentityComponent = useMemo(() => {
    const handleConfirmDialog = async () => {
      try {
        await new Promise<void>(() => {
          confirm({
            id: deviceIdentityId,
            name: data?.data.name,
          });
        });
      } catch (error) {
        alert(error);
      }
    };

    const toolbarBody = (
      <Box
        display="flex"
        flexDirection={isTablet ? 'column' : 'row'}
        justifyContent={'space-between'}
        alignItems={isTablet ? 'flex-start' : 'center'}
      >
        <Button
          id="backToList"
          variant="text"
          onClick={() => navigate(`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.ECOSYSTEM}`)}
          sx={{ mb: isTablet ? '1rem' : 0 }}
          startIcon={<ArrowCircleLeftIcon />}
        >
          {t('backToList')}
        </Button>

        <Box display="flex" ml={isTablet ? 0 : '4rem'}>
          <Button
            id="setInterventions"
            variant="outlined"
            disabled={false}
            startIcon={<SystemUpdateAltIcon sx={{ mr: '0.6rem' }} />}
            onClick={() => window.print()}
          >
            {t('export')}
          </Button>
          <Button
            startIcon={<EditIcon sx={{ mr: '0.6rem' }} />}
            variant="contained"
            color="primary"
            sx={{ ml: '8px', pt: '0.4rem' }}
            onClick={() => openDialog(deviceIdentityId)}
          >
            {t('edit')}
          </Button>
          <Button
            startIcon={<DeleteIcon sx={{ mr: '0.6rem' }} />}
            variant="contained"
            color="error"
            sx={{ ml: '8px', pt: '0.4rem' }}
            onClick={() => handleConfirmDialog()}
          >
            {t('delete')}
          </Button>
        </Box>
      </Box>
    );

    return (
      <>
        <FixedToolbar
          event={'scroll'}
          slideInPredicate={scrolledAmountIsGreaterThan(100)}
          body={toolbarBody}
          sx={{ '@media print': { display: 'none' } }}
        />

        {!isLoading ? (
          <>
            {data?.data.id !== deviceIdentityId ? (
              <Page404 />
            ) : (
              <>
                <Card
                  sx={{
                    flex: 1,
                    overflowY: 'auto',
                    padding: 1,
                    '@media print': { display: 'none' },
                  }}
                  elevation={1}
                >
                  {toolbarBody}
                </Card>
                <DeviceIdentityPanel deviceIdentity={data?.data} isLoading={isLoading} isError={isError} />
                <AddEditDialog />

                <ConfirmDialog />
              </>
            )}
          </>
        ) : (
          <LoadingMessage />
        )}
      </>
    );
  }, [
    AddEditDialog,
    ConfirmDialog,
    confirm,
    data?.data,
    deviceIdentityId,
    isError,
    isLoading,
    isTablet,
    navigate,
    openDialog,
    t,
  ]);

  useEffect(() => {
    if (deleteDeviceIdentityFlags.isSuccess) {
      navigate(`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.ECOSYSTEM}`);
    }
  }, [deleteDeviceIdentityFlags, navigate]);

  useLayoutEffect(() => {
    const beforePrintListener = () => {
      document.body.id = 'print';
    };
    const afterPrintListener = () => {
      document.body.id = '';
    };

    if (window) {
      window.addEventListener('beforeprint', beforePrintListener);
      window.addEventListener('afterprint', afterPrintListener);
    }

    return () => {
      window.removeEventListener('beforeprint', beforePrintListener);
      window.removeEventListener('afterprint', afterPrintListener);
    };
  }, []);

  return <>{DeviceIdentityComponent}</>;
};
export default DeviceIdentity;
