import StoreFilters from '@/components/Shared/Filters/StoreFilters';
import { StoreFilterConfig } from '@/components/Shared/Filters/typings';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { useLazyGetFilteredAlarmsQuery } from '@/redux/api/fleet/alarmsApiSlice';
import { CHANNELS, ISSUE_STATUS, SEVERITY } from '@/shared/constants';
import { DateRangeDefaultValue } from '@/shared/utils';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAlarmColumns from './useAlarmsColumn';
import { FilterAlarmRequest, FleetAlarm } from './typings';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import IssueDetails from '@/components/Shared/AlarmDialog/IssueDetails';
import { Box } from '@mui/material';
import MaterialTable, { Column, Query, QueryResult } from '@material-table/core';
import { useQueryStringFilters } from './useQueryStringFilters';
import { Schema as S } from '@effect/schema';
import { Either, Option } from 'effect';

export const AlarmsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<FleetAlarm>));

const FilterSeverity = S.Enums({
  Critical: 'critical',
  NonCritical: 'non-critical',
  Both: 'both',
});

const FilterStatus = S.Enums({
  Active: 'active',
  Dismissed: 'dismissed',
  Both: 'both',
});

const dateRange = S.Struct({
  start: S.Number,
  end: S.Number,
});

const FILTER_KEYS = {
  START: 'dateRangeStart',
  END: 'dateRangeEnd',
  STATUS: 'status',
  SEVERITY: 'severity',
  PAGE: 'page',
  PAGESIZE: 'pageSize',
  ORDERBY: '',
} as const;

const FilterState = S.partial(
  S.Struct({
    [FILTER_KEYS.START]: S.NumberFromString,
    [FILTER_KEYS.END]: S.NumberFromString,
    [FILTER_KEYS.PAGE]: S.NumberFromString,
    [FILTER_KEYS.SEVERITY]: FilterSeverity,
    [FILTER_KEYS.STATUS]: FilterStatus,
  })
);

type FilterStateDecoded = typeof FilterState.Type;
type FilterStateEncoded = typeof FilterState.Encoded;

const encodeFilterState = S.encodeEither(FilterState);
const decodeFilterState = S.decodeUnknownEither(FilterState);

export default function AlarmsPanel({
  filters = true,
  pageSize = 10,
  selection = true,
  exportData = true,
  hideTableHeader = false,
  // defaultRange = {
  //   end: dayjs().endOf('day'),
  //   start: dayjs().subtract(1, 'month').startOf('day'),
  // },
  isFleet,
}: {
  navigationTabs?: boolean;
  filters?: boolean;
  pageSize?: number;
  selection?: boolean;
  search?: boolean;
  exportData?: boolean;
  hideTableHeader?: boolean;
  defaultRange?: {
    end: Dayjs;
    start: Dayjs;
  };
  isFleet: boolean;
}) {
  const dateRangeDefaultValue = useMemo(
    () => new DateRangeDefaultValue(dayjs().subtract(1, 'month').startOf('day'), dayjs().endOf('day')),
    []
  );
  const isFleetOverview = window.location.toString().includes('overview');
  const { getQueryFilters, setQueryFilters, hasFilterHydrationCompleted, notifyHydrationCompleted } =
    useQueryStringFilters<FilterStateDecoded, FilterStateEncoded>({
      defaultFilterValues: isFleetOverview
        ? {}
        : {
            dateRangeStart: dateRangeDefaultValue.toUnixAsString().start,
            dateRangeEnd: dateRangeDefaultValue.toUnixAsString().end,
            page: '1',
            severity: 'both',
            status: 'both',
          },
      encode: encodeFilterState,
      decode: decodeFilterState,
    });
  const { dateRangeStart, dateRangeEnd, severity, status } = getQueryFilters();
  const [getFilteredAlarms, { data, isLoading, isFetching }] = useLazyGetFilteredAlarmsQuery();
  const fallbackDateRangeStart = Number(dateRangeDefaultValue.toUnixAsString().start);
  const fallbackDateRangeEnd = Number(dateRangeDefaultValue.toUnixAsString().end);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedAlarm, setSelectedAlarm] = useState<FleetAlarm | null>(null);
  const tableRef = useRef<{
    onQueryChange: () => void;
    state: {
      data?: Array<FleetAlarm>;
      columns: Array<Column<FleetAlarm>>;
    };
  }>();
  const handleOpenDialog = (id: string) => {
    const alarm = data?.data.items.find((alarm) => alarm.id === id) as FleetAlarm;
    setSelectedAlarm(alarm);
    setOpenDialog(true);
  };
  const columns = useAlarmColumns({
    onDetailClick: handleOpenDialog,
    isFleetAlarm: true,
  });

  const { t } = useTranslation();

  const filterConfig: StoreFilterConfig[] = useMemo(
    () => [
      {
        id: 'dateRange',
        label: t('dateRange'),
        kind: 'dateRangeWithFixedRanges',
        defaultValue: {
          start: dateRangeStart || fallbackDateRangeStart,
          end: dateRangeEnd || fallbackDateRangeEnd,
        },
      },
      {
        id: FILTER_KEYS.SEVERITY,
        label: t(FILTER_KEYS.SEVERITY),
        kind: 'autocomplete',
        options: [
          {
            label: t('both'),
            optionId: 'both',
          },
          {
            label: t(SEVERITY.CRITICAL),
            optionId: SEVERITY.CRITICAL,
          },
          {
            label: t(SEVERITY.NON_CRITICAL),
            optionId: SEVERITY.NON_CRITICAL,
          },
        ],
        defaultValue: {
          label: t(severity || 'both'),
          optionId: severity || 'both',
        },
      },
      {
        id: FILTER_KEYS.STATUS,
        label: t(FILTER_KEYS.STATUS),
        kind: 'autocomplete',
        options: [
          {
            label: t('both'),
            optionId: 'both',
          },
          {
            label: t(ISSUE_STATUS.ACTIVE),
            optionId: ISSUE_STATUS.ACTIVE,
          },
          {
            label: t('resolved'),
            optionId: ISSUE_STATUS.DISMISSED,
          },
        ],
        defaultValue: {
          label: t(status || 'both'),
          optionId: status || 'both',
        },
      },
    ],
    [dateRangeEnd, dateRangeStart, fallbackDateRangeEnd, fallbackDateRangeStart, severity, status, t]
  );

  const handleFiltersApplied = useCallback(
    (filtersApplied: Map<string, string>) => {
      const getFilterApplied = (key: string) => Option.fromNullable(filtersApplied.get(key));

      const dateRangeFilter = Either.try(() => {
        const dateRange = filtersApplied.get('dateRange');
        if (dateRange != null) {
          return JSON.parse(dateRange);
        }
        return Either.left('No date range present');
      }).pipe(
        Either.getOrElse(() => []),
        S.decodeUnknownEither(dateRange),
        Either.match({
          onLeft: () => [],
          onRight: (dateAsJSON) => [
            { key: FILTER_KEYS.START, value: dateAsJSON.start },
            { key: FILTER_KEYS.END, value: dateAsJSON.end },
          ],
        })
      );

      const severityFilter = getFilterApplied('severity').pipe(
        Option.getOrNull,
        S.decodeUnknownEither(FilterSeverity),
        Either.match({
          onLeft: () => [],
          onRight: (right) => [
            {
              key: FILTER_KEYS.SEVERITY,
              value: right,
            },
          ],
        })
      );

      const statusFilter = getFilterApplied('status').pipe(
        Option.getOrNull,
        S.decodeUnknownEither(FilterStatus),
        Either.match({
          onLeft: () => [],
          onRight: (right) => [
            {
              key: FILTER_KEYS.STATUS,
              value: right,
            },
          ],
        })
      );
      const payload = [...dateRangeFilter, ...severityFilter, ...statusFilter];

      if (!payload.length) {
        return;
      }
      setQueryFilters(payload);
      tableRef?.current?.onQueryChange();
    },
    [setQueryFilters]
  );

  const handleFiltersCleared = () => {
    setQueryFilters([
      { key: FILTER_KEYS.START, value: Number(dateRangeDefaultValue.toUnixAsString().start) },
      { key: FILTER_KEYS.END, value: Number(dateRangeDefaultValue.toUnixAsString().end) },
      { key: FILTER_KEYS.STATUS, value: 'both' },
      { key: FILTER_KEYS.SEVERITY, value: 'both' },
      { key: FILTER_KEYS.PAGE, value: 1 },
    ]);
  };

  const getPayload = useCallback(
    (query: Query<FleetAlarm>) => {
      const sortCollection = query.orderByCollection.length ? query.orderByCollection[0] : null;
      const field = sortCollection ? (columns[sortCollection?.orderBy].field as keyof FleetAlarm) : null;
      const direction = (sortCollection?.orderDirection as 'desc' | 'asc') || null;
      const hasSorting = field != null && direction != null;
      const payload: FilterAlarmRequest = {
        dateRangeStart: dateRangeStart || Number(dateRangeDefaultValue.toUnixAsString().start),
        dateRangeEnd: dateRangeEnd || Number(dateRangeDefaultValue.toUnixAsString().end),
        status: status || undefined,
        severity: severity || undefined,
        page: query.page + 1,
        pageSize: query.pageSize,
        sortBy: hasSorting ? { field: field, direction: direction } : undefined,
      };

      if (payload.status === 'both') {
        delete payload?.status;
      }
      if (payload.severity === 'both') {
        delete payload?.severity;
      }
      return payload;
    },
    [columns, dateRangeDefaultValue, dateRangeEnd, dateRangeStart, severity, status]
  );

  const handleFetchTableData = useCallback(
    (query: Query<FleetAlarm>) =>
      new Promise<QueryResult<FleetAlarm>>((resolve, reject) => {
        getFilteredAlarms(getPayload(query), true)
          .unwrap()
          .then((result) => {
            const filteredItems = query.search
              ? result.data.items.filter((item) =>
                  Object.values(item).some((value) => String(value).toLowerCase().includes(query.search.toLowerCase()))
                )
              : result.data.items;
            if (!hasFilterHydrationCompleted) {
              notifyHydrationCompleted();
            }
            !isFleetOverview && setQueryFilters([{ key: 'page', value: result.data?.page }]);

            return resolve({
              data: filteredItems,
              page: result.data.page - 1,
              totalCount: result.data?.itemsCount || 0,
            });
          })
          .catch((err) => reject(err));
      }),
    [
      getFilteredAlarms,
      getPayload,
      hasFilterHydrationCompleted,
      isFleetOverview,
      notifyHydrationCompleted,
      setQueryFilters,
    ]
  );

  const handleCloseDialog = () => {
    setSelectedAlarm(null);
    setOpenDialog(false);
  };

  const { dialog } = useCulliganDialog({
    open: openDialog,
    handleClose: handleCloseDialog,
    tabs: [
      {
        id: 'alarm',
        label: t('alarm'),
        body: selectedAlarm && <IssueDetails data={selectedAlarm} isFleet={isFleet} />,
      },
    ],
    styles: {
      bodyContainer: { p: 0, width: '25rem' },
    },
  });

  return (
    <Box>
      {filters && (
        <StoreFilters
          filterConfigs={filterConfig as StoreFilterConfig[]}
          onFiltersApplied={handleFiltersApplied}
          onFiltersCleared={handleFiltersCleared}
        />
      )}

      <MaterialTable
        tableRef={tableRef}
        title={t(CHANNELS.ALARMS)}
        data={handleFetchTableData}
        columns={columns as Array<Column<FleetAlarm>>}
        isLoading={isLoading || isFetching}
        options={{
          pageSize,
          searchDebounceDelay: 400,
        }}
      />

      {openDialog && dialog}
    </Box>
  );
}
