import useAddEditDialog from '@/hooks/useAddEditDialog';
import {
  useDeleteConsumableSubsetMutation,
  useGetConsumableSubsetsQuery,
  useIsConsumableSubsetUniqueMutation,
  useLazyGetConsumableSubsetQuery,
  usePostConsumableSubsetMutation,
  usePutConsumableSubsetMutation,
  useUploadCoverImageMutation,
} from '@/redux/api/system/consumableSubsetsApiSlice';
import { useTranslation } from 'react-i18next';
import { ConsumableSubsetReq, ConsumableSubsetRes } from './typings';
import useConsumableSubsetsColumns from './useConsumableSubsetsColumns';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { CONSUMABLE_KIND, EXPIRATION_METHOD } from '@/shared/constants';
import { ApiResult, CustomErrors } from '@typings';
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { CommonIsEntityUniqueRes } from '../../typings';
import SI_UNITS from '../Telemetry/si_units.json';
import { useLazyGetConsumablesQuery } from '@/redux/api/system/consumablesApiSlice';
import { isValidNumber } from '@/shared/utils';

export const ConsumableSubsetsTable = withErrorLoadingManagement(
  withResetNavigationState(GenericExportTable<ConsumableSubsetRes>)
);

export default function ConsumableSubsetsPanel() {
  const { t } = useTranslation();

  const [getConsumableSubset] = useLazyGetConsumableSubsetQuery();
  const { data, isLoading, isError } = useGetConsumableSubsetsQuery();
  const [postConsumableSubsetTrigger] = usePostConsumableSubsetMutation();
  const [putConsumableSubsetTrigger] = usePutConsumableSubsetMutation();
  const [deleteConsumableSubsetTrigger] = useDeleteConsumableSubsetMutation();
  const [isConsumableSubsetUnique] = useIsConsumableSubsetUniqueMutation();
  const [getAllConsumables] = useLazyGetConsumablesQuery();
  const [uploadCoverImage] = useUploadCoverImageMutation();

  const fieldsToHide = [
    'drinkName',
    'drinkFlavor',
    'drinkDescription',
    'ingredients',
    'tags',
    'themeName',
    'lightestColor',
    'lighterColor',
    'lightColor',
    'normalColor',
    'darkColor',
    'darkerColor',
    'foregroundColor',
    'textColor',
    'coverImage',
    'energyValueKj',
    'energyValueKcal',
    'proteinValue',
    'carbohydratesValue',
    'perWhichSugars',
    'fatValue',
    'perWhichSaturated',
    'fibreValue',
    'sodiumValue',
    'flavored',
    'nominalMixRatio',
    'mixRatioScalars',
  ];

  const validateNutritionFact = (value: number | string): true | string => {
    if (!isValidNumber(value)) {
      return t('fieldNumberError') as string;
    }
    return typeof value === 'number' && value >= 0 ? true : (t('fieldAsPositiveNumberError') as string);
  };

  const { AddEditDialog, openDialog } = useAddEditDialog<ConsumableSubsetReq>({
    title: t('consumableSubsets'),
    groups: {
      nutrifact: {
        title: t('nutrifact'),
      },
    },
    baseConfig: [
      {
        name: 'name',
        placeholder: t('name'),
        options: { required: t('fieldRequiredError') as string },
        needsAsyncValidation: true,
      },
      {
        name: 'id',
        placeholder: t('key'),
        options: { required: t('fieldRequiredError') as string },
        needsAsyncValidation: true,
      },
      {
        name: 'description',
        placeholder: t('description'),
        options: { required: t('fieldRequiredError') as string },
      },
      {
        type: 'autocomplete',
        name: 'consumable',
        placeholder: t('consumable'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: () => {
            return getAllConsumables().then((consumables) => {
              return consumables?.data?.data?.items?.map((t) => ({ label: `${t.name} (${t.id})`, value: t.id })) || [];
            });
          },
        },
      },
      {
        name: 'nominalMixRatio',
        type: 'number',
        adornmentStartValue: '1/',
        placeholder: t('nominalMixRatio'),
        options: {
          required: t('fieldRequiredError') as string,
          deps: ['consumable'],
        },
        helperText: t('higherNumber') as string,
      },
      {
        name: 'mixRatioScalars',
        placeholder: t('mixRatioScalars'),
        options: { required: t('fieldRequiredError') as string },
        helperText: t('commaSeparatedNumbersHelperText') as string,
      },
      {
        type: 'checkbox',
        name: 'flavored',
        placeholder: t('flavored'),
        options: {
          deps: ['consumable'],
        },
      },
      {
        name: 'expirationMethod',
        placeholder: t('expirationMethod'),
        type: 'autocomplete',
        options: {
          required: t('fieldRequiredError') as string,
        },
        selectConfig: {
          options: Object.values(EXPIRATION_METHOD).map((value) => ({ label: t(value), value })),
        },
      },
      {
        name: 'lifespan',
        placeholder: t('lifespan'),
        type: 'number',
        options: {
          deps: ['expirationMethod'],
          validate: (value, formValues) => {
            if (formValues.expirationMethod.value === EXPIRATION_METHOD.REPLACE_AFTER) {
              if (value === '') {
                return t('fieldRequiredError') as string;
              }
              if (!isValidNumber(value)) {
                return t('fieldInDaysError') as string;
              }
              const valueAsNumber = Number(value);
              if (valueAsNumber < 1) {
                return t('fieldInDaysError') as string;
              }
            }
            return true;
          },
        },
        helperText: t('fieldInDays') as string,
      },
      {
        type: 'autocomplete',
        name: 'unitOfMeasure',
        placeholder: t('unitOfMeasure'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: SI_UNITS.map((unit) => ({ ...unit, label: `${unit.label} - ${unit.value}` })),
        },
      },
      {
        name: 'rangeExhaust',
        type: 'number',
        placeholder: t('rangeExhaust'),
        options: {
          required: t('fieldRequiredError') as string,
          deps: ['rangeTrigger', 'rangeFullCapacity'],
          validate: (value, { rangeFullCapacity, rangeTrigger }) => {
            if (!isValidNumber(value)) {
              return t('fieldNumberError') as string;
            }
            const canValidate = [rangeFullCapacity, rangeTrigger].map(isValidNumber).every((valid) => valid);
            if (!canValidate) {
              return t('fieldOutOfRange') as string;
            }
            return value < rangeTrigger ? true : (t('fieldOutOfRange') as string);
          },
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'rangeTrigger',
        type: 'number',
        placeholder: t('rangeTrigger'),
        options: {
          required: t('fieldRequiredError') as string,
          deps: ['rangeExhaust', 'rangeFullCapacity'],
          validate: (value, { rangeExhaust, rangeFullCapacity }) => {
            if (!isValidNumber(value)) {
              return t('fieldNumberError') as string;
            }
            const canValidate = [rangeFullCapacity, rangeExhaust].map(isValidNumber).every((valid) => valid);
            if (!canValidate) {
              return t('fieldOutOfRange') as string;
            }
            return value < rangeFullCapacity ? true : (t('fieldOutOfRange') as string);
          },
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'rangeFullCapacity',
        type: 'number',
        placeholder: t('rangeFullCapacity'),
        options: {
          deps: ['rangeExhaust', 'rangeTrigger'],
          required: t('fieldRequiredError') as string,
          validate: (value, { rangeExhaust, rangeTrigger }) => {
            if (!isValidNumber(value)) {
              return t('fieldNumberError') as string;
            }
            const canValidate = [rangeTrigger, rangeExhaust].map(isValidNumber).every((valid) => valid);
            if (!canValidate) {
              return t('fieldOutOfRange') as string;
            }
            return value > rangeTrigger ? true : (t('fieldOutOfRange') as string);
          },
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'drinkName',
        placeholder: t('drinkName'),
        options: {
          required: t('fieldRequiredError') as string,
          deps: ['consumable'],
        },
      },
      {
        name: 'drinkFlavor',
        placeholder: t('drinkFlavor'),
        options: {
          required: t('fieldRequiredError') as string,
          deps: ['consumable'],
        },
      },
      {
        name: 'drinkDescription',
        placeholder: t('drinkDescription'),
        options: {
          required: t('fieldRequiredError') as string,
          deps: ['consumable'],
        },
      },
      {
        name: 'ingredients',
        placeholder: t('ingredients'),
        helperText: t('commaSeparatedValuesHelperText', { subject: t('ingredients') }) as string,
        options: {
          deps: ['consumable'],
        },
      },
      {
        name: 'tags',
        placeholder: t('tags'),
        helperText: t('commaSeparatedValuesHelperText', { subject: t('tags') }) as string,
        options: {
          deps: ['consumable'],
        },
      },
      {
        name: 'themeName',
        placeholder: t('themeName'),
        options: {
          deps: ['consumable'],
        },
      },
      {
        type: 'color',
        name: 'lightestColor',
        placeholder: t('lightestColor'),
        options: {
          deps: ['consumable'],
        },
      },
      {
        type: 'color',
        name: 'lighterColor',
        placeholder: t('lighterColor'),
        options: {
          deps: ['consumable'],
        },
      },
      {
        type: 'color',
        name: 'lightColor',
        placeholder: t('lightColor'),
        options: {
          deps: ['consumable'],
        },
      },
      {
        type: 'color',
        name: 'normalColor',
        placeholder: t('normalColor'),
        options: {
          deps: ['consumable'],
        },
      },
      {
        type: 'color',
        name: 'darkColor',
        placeholder: t('darkColor'),
        options: {
          deps: ['consumable'],
        },
      },
      {
        type: 'color',
        name: 'darkerColor',
        placeholder: t('darkerColor'),
        options: {
          deps: ['consumable'],
        },
      },
      {
        type: 'color',
        name: 'foregroundColor',
        placeholder: t('foregroundColor'),
        options: {
          deps: ['consumable'],
        },
      },
      {
        type: 'color',
        name: 'textColor',
        placeholder: t('textColor'),
        options: {
          deps: ['consumable'],
        },
      },
      {
        type: 'file',
        name: 'coverImage',
        placeholder: t('coverImage'),
        fileConfig: {
          preview: { default: true },
          accept: 'image/png, image/jpeg, image/svg+xml',
        },
        helperText: t('acceptedTypes', {
          types: 'image/png, image/jpeg, image/svg+xml',
          interpolation: { escapeValue: false },
        }) as string,
      },
      {
        name: 'energyValueKj',
        placeholder: t('energyValueKj'),
        group: 'nutrifact',
        type: 'number',
        options: {
          deps: ['consumable'],
          required: t('fieldRequiredError') as string,
          validate: (value) => validateNutritionFact(value),
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'energyValueKcal',
        type: 'number',
        placeholder: t('energyValueKcal'),
        group: 'nutrifact',
        options: {
          deps: ['consumable'],
          required: t('fieldRequiredError') as string,
          validate: (value) => validateNutritionFact(value),
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'proteinValue',
        type: 'number',
        placeholder: t('proteinValue'),
        group: 'nutrifact',
        options: {
          deps: ['consumable'],
          required: t('fieldRequiredError') as string,
          validate: (value) => validateNutritionFact(value),
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'carbohydratesValue',
        placeholder: t('carbohydratesValue'),
        group: 'nutrifact',
        type: 'number',
        options: {
          required: t('fieldRequiredError') as string,
          validate: (value) => validateNutritionFact(value),
          deps: ['consumable'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'perWhichSugars',
        type: 'number',
        placeholder: t('perWhichSugars'),
        group: 'nutrifact',
        options: {
          required: t('fieldRequiredError') as string,
          validate: (value) => validateNutritionFact(value),
          deps: ['consumable'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'fatValue',
        type: 'number',
        placeholder: t('fatValue'),
        group: 'nutrifact',
        options: {
          required: t('fieldRequiredError') as string,
          validate: (value) => validateNutritionFact(value),
          deps: ['consumable'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'perWhichSaturated',
        type: 'number',
        placeholder: t('perWhichSaturated'),
        group: 'nutrifact',
        options: {
          required: t('fieldRequiredError') as string,
          validate: (value) => validateNutritionFact(value),
          deps: ['consumable'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'fibreValue',
        type: 'number',
        placeholder: t('fibreValue'),
        group: 'nutrifact',
        options: {
          required: t('fieldRequiredError') as string,
          validate: (value) => validateNutritionFact(value),
          deps: ['consumable'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'sodiumValue',
        placeholder: t('sodiumValue'),
        type: 'number',
        group: 'nutrifact',
        options: {
          required: t('fieldRequiredError') as string,
          validate: (value) => validateNutritionFact(value),
          deps: ['consumable'],
        },
        helperText: t('fieldNumberError') as string,
      },
    ],
    getEditData: async (dialogId: string | boolean) => {
      const res: any = await getConsumableSubset(dialogId as string);
      const consumableSubset: ConsumableSubsetRes = { ...res.data?.data };
      consumableSubset.ingredients = consumableSubset.ingredients
        ? (consumableSubset.ingredients as string[]).join(', ')
        : '';
      consumableSubset.tags = consumableSubset.tags ? (consumableSubset.tags as string[]).join(', ') : '';
      consumableSubset.mixRatioScalars = consumableSubset.mixRatioScalars
        ? (consumableSubset.mixRatioScalars as string[]).join(', ')
        : '';

      return consumableSubset;
    },
    formValidation: async (formState, setCustomError, clearErrors, errors, dialogId) => {
      const consumableValue = formState.consumable;

      if (!consumableValue) {
        fieldsToHide.forEach((field) => {
          setCustomError(field, CustomErrors.HIDE_FIELD);
        });
      } else {
        const consumablesList = await getAllConsumables();
        const consumable = consumablesList.data?.data.items.filter(
          (cons: { id: string }) => cons.id === consumableValue.value
        );
        const isSubsetEnhancement = consumable && consumable[0].kind === CONSUMABLE_KIND.ENHANCEMENTS;

        if (!isSubsetEnhancement) {
          fieldsToHide.forEach((field) => {
            setCustomError(field, CustomErrors.HIDE_FIELD);
          });
        } else {
          let fieldsToBeShown: Array<string> = [];

          // We check if the fields are currently hidden, otherwise we will clear valid validation errors.
          fieldsToHide.forEach((field) => {
            if (errors[field] && errors[field]?.message === CustomErrors.HIDE_FIELD) {
              fieldsToBeShown.push(field);
            }
            if (!errors[field] && formState[field]) {
              clearErrors([field]);
            }
          });
          if (fieldsToBeShown.length) {
            clearErrors(fieldsToBeShown);
          }
        }
      }

      if (formState?.expirationMethod?.value === EXPIRATION_METHOD.REPLACE_AFTER) {
        clearErrors(['lifespan']);
      }
      if (formState?.expirationMethod?.value !== EXPIRATION_METHOD.REPLACE_AFTER) {
        clearErrors(['lifespan']);
        setCustomError('lifespan', CustomErrors.HIDE_FIELD);
      }

      const isEditing = typeof dialogId === 'string';

      const isConsumableSubsetUniqueBody = {
        ...((formState.id && { id: formState.id || dialogId }) || {}),
        ...((formState.name && { name: formState.name }) || {}),
      };

      let isNameUnique: boolean | undefined = false;
      let isIdUnique: boolean | undefined = false;

      if (
        Object.keys(isConsumableSubsetUniqueBody).length === 0 ||
        (isEditing && data?.data.items.find((t) => t.id === dialogId && t.name === isConsumableSubsetUniqueBody.name))
      ) {
        if (isConsumableSubsetUniqueBody.id) {
          clearErrors(['id']);
          clearErrors(['root.id']);
        }

        if (isConsumableSubsetUniqueBody.name) {
          clearErrors(['name']);
          clearErrors(['root.name']);
        }
        return;
      }

      const isConsumableSubsetUniqueRes = await isConsumableSubsetUnique({
        body: isConsumableSubsetUniqueBody,
        isEditing,
      });
      if (
        (
          isConsumableSubsetUniqueRes as {
            error: FetchBaseQueryError | SerializedError;
          }
        ).error
      ) {
        // TODO - Handle the possible error
        return;
      }

      isNameUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isConsumableSubsetUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.name
      );
      isIdUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isConsumableSubsetUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.id
      );
      isNameUnique = (isNameUnique === undefined && true) || isNameUnique;
      isIdUnique = (isIdUnique === undefined && true) || isIdUnique;

      if (!isEditing && !isIdUnique) {
        setCustomError('id', CustomErrors.UNIQUE);
        setCustomError('root.id', CustomErrors.UNIQUE);
      }

      if (!isEditing && isIdUnique) {
        clearErrors(['id']);
        clearErrors(['root.id']);
      }

      if (!isNameUnique) {
        setCustomError('name', CustomErrors.UNIQUE);
        setCustomError('root.name', CustomErrors.UNIQUE);
      }

      if (isNameUnique) {
        clearErrors(['name']);
        clearErrors(['root.name']);
      }
    },
    onSubmit: async (dialogId: string | boolean, data) => {
      const isEditing = typeof dialogId === 'string';

      function _uploadCoverImage(id: string) {
        const hasFiles = Object.values(data).some((value) => value instanceof File);
        if (hasFiles) {
          Object.values(data).forEach(async (value) => {
            if (value instanceof File) {
              const form = new FormData();
              form.append('id', id);
              form.append('mime', value?.type);
              form.append('coverImage', value);
              await uploadCoverImage(form);
            }
          });
        }
      }

      if (data.mixRatioScalars) {
        if (typeof data.mixRatioScalars === 'string') {
          if (data.mixRatioScalars.includes(',')) {
            data.mixRatioScalars = data.mixRatioScalars
              .split(',')
              .filter((mixRatioScalar) => !!mixRatioScalar)
              .map((mixRatioScalar) => parseFloat(mixRatioScalar.trim()));
          } else {
            data.mixRatioScalars = [parseFloat(data.mixRatioScalars.trim())];
          }
        }
      } else {
        data.mixRatioScalars = [];
      }

      data.flavored = data.flavored === true;

      if (!data.nominalMixRatio) {
        data.nominalMixRatio = 0;
      }

      if (!data.rangeExhaust) {
        data.rangeExhaust = 0;
      }

      if (data.expirationMethod === EXPIRATION_METHOD.EXPIRATION_DATE) {
        delete data.lifespan;
      }

      if (data.ingredients) {
        data.ingredients = (data.ingredients as unknown as string)
          .split(',')
          .filter((ingredient) => !!ingredient)
          .map((ingredient) => ingredient.trim());
      } else {
        data.ingredients = [];
      }

      if (data.tags) {
        data.tags = (data.tags as unknown as string)
          .split(',')
          .filter((tag) => !!tag)
          .map((tag) => tag.trim());
      } else {
        data.tags = [];
      }

      const submitData = { ...data };
      typeof submitData.coverImage !== 'string' && delete submitData.coverImage;
      if (isEditing) {
        await putConsumableSubsetTrigger({ id: dialogId, body: submitData });
        if (data.coverImage && data.coverImage instanceof File) _uploadCoverImage(dialogId);
      } else {
        const res = await postConsumableSubsetTrigger(submitData);
        if (data.coverImage) _uploadCoverImage((res as any).data?.data?.id);
      }
    },
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t('consumablesSubset'),
    message: (extra: any) => t('deleteMessage', { entity: t('consumablesSubset'), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteConsumableSubsetTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useConsumableSubsetsColumns(openDialog, confirm);

  return (
    <>
      <ConsumableSubsetsTable
        title={t('consumableSubsets')}
        data={data?.data.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('consumableSubsets')}
        onResetStateButtonClick={openDialog}
        exportData={true}
        selection={true}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
