import { useGetDeviceIdentitiesQuery } from '@/redux/api/system/deviceIdentitiesApiSlice';
import { useDeviceIdentityColumns } from './useDeviceIdentityColumns';
import { DeviceIdentityRes } from './typings';
import { useTranslation } from 'react-i18next';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import useDeviceIdentityDialogs from './useDeviceIdentityDialogs';
import { ECOSYSTEM } from '@/shared/constants';

export const DeviceIdentitiesTable = withErrorLoadingManagement(
  withResetNavigationState(GenericExportTable<DeviceIdentityRes>)
);

export default function DeviceIdentitiesPanel() {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetDeviceIdentitiesQuery();

  const { AddEditDialog, openDialog, ConfirmDialog, confirm } = useDeviceIdentityDialogs();

  const columns = useDeviceIdentityColumns(openDialog, confirm, data?.data.appEnv);

  return (
    <>
      <DeviceIdentitiesTable
        title={t(ECOSYSTEM.DEVICE_IDENTITIES)}
        data={data?.data.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('deviceIdentity')}
        onResetStateButtonClick={openDialog}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
