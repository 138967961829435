import useConfigColumns from './useConfigColumns';
import {
  useDeleteConfigMutation,
  useGetConfigsQuery,
  useLazyGetConfigQuery,
  usePutConfigMutation,
  usePostConfigMutation,
  useIsConfigUniqueMutation,
} from '@/redux/api/system/configsApiSlice';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { useTranslation } from 'react-i18next';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { CHANNELS, PRIMITIVES, ROLES } from '@/shared/constants';
import { ConfigReq, ConfigRes } from './typings';
import { ApiResult, CustomErrors } from '@/shared/typings';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { CommonIsEntityUniqueRes } from '../../typings';

export const ConfigsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<ConfigRes>));

export default function ConfigPanel() {
  const { t } = useTranslation();

  // Queries and mutations
  const { data, isLoading, isError } = useGetConfigsQuery();
  const [getConfig] = useLazyGetConfigQuery();
  const [postConfigTrigger] = usePostConfigMutation();
  const [putConfigTrigger] = usePutConfigMutation();
  const [deleteConfigTrigger] = useDeleteConfigMutation();
  const [isConfigUnique] = useIsConfigUniqueMutation();

  const { AddEditDialog, openDialog } = useAddEditDialog<ConfigReq>({
    title: t(CHANNELS.CONFIGS),
    baseConfig: [
      {
        name: 'name',
        placeholder: t('name'),
        options: { required: t('fieldRequiredError') as string },
        needsAsyncValidation: true,
      },
      {
        name: 'id',
        placeholder: t('key'),
        options: { required: t('fieldRequiredError') as string },
        needsAsyncValidation: true,
      },
      {
        name: 'description',
        placeholder: t('description'),
        options: { required: t('fieldRequiredError') as string },
      },
      {
        type: 'checkbox',
        name: 'editable',
        placeholder: t('editable'),
      },
      {
        type: 'autocomplete',
        name: 'type',
        placeholder: t('type'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(PRIMITIVES).map((primitive: string) => ({
            label: t(primitive),
            value: primitive,
          })),
        },
      },
      {
        name: 'string',
        placeholder: t('stringOptions'),
        options: {
          required: false,
          deps: ['type'],
          validate: (value, formValues) => {
            if (formValues.type.value === PRIMITIVES.STRING) {
              return value && Array.isArray(new Array(value)) ? true : (t('arrayInvalid') as string);
            }
            return true;
          },
        },
        helperText: t('fieldListOfString') as string,
      },
      {
        name: 'fullRangeMin',
        placeholder: t('fullRangeMin'),
        type: 'number',
        options: {
          deps: ['type'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'fullRangeMax',
        type: 'number',
        placeholder: t('fullRangeMax'),
        options: {
          deps: ['type'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'safeRangeMin',
        type: 'number',
        placeholder: t('safeRangeMin'),
        options: {
          required: false,
          deps: ['type'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'safeRangeMax',
        placeholder: t('safeRangeMax'),
        type: 'number',
        options: {
          required: false,
          deps: ['type'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        type: 'autocomplete',
        name: 'role',
        placeholder: t('roles'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(ROLES).map((role) => ({ label: t(role), value: role })),
          multiple: true,
        },
      },
    ],
    getEditData: async (id: string | boolean) => {
      const res = await getConfig(id as string);
      const config: any = { ...res.data?.data };
      return config;
    },
    formValidation: async (formState, setCustomError, clearErrors, _, dialogId) => {
      const type = formState.type?.value;
      if (type !== PRIMITIVES.STRING) {
        setCustomError('string', CustomErrors.HIDE_FIELD);
      } else {
        clearErrors(['string']);
      }
      if (type !== PRIMITIVES.NUMBER) {
        setCustomError('fullRangeMin', CustomErrors.HIDE_FIELD);
        setCustomError('fullRangeMax', CustomErrors.HIDE_FIELD);
        setCustomError('safeRangeMin', CustomErrors.HIDE_FIELD);
        setCustomError('safeRangeMax', CustomErrors.HIDE_FIELD);
      } else {
        clearErrors(['fullRangeMin']);
        clearErrors(['fullRangeMax']);
        clearErrors(['safeRangeMin']);
        clearErrors(['safeRangeMax']);
      }

      const isEditing = typeof dialogId === 'string';
      const isConfigUniqueBody = {
        ...((formState.id && { id: formState.id || dialogId }) || {}),
        ...((formState.name && { name: formState.name }) || {}),
      };

      let isNameUnique: boolean | undefined = false;
      let isIdUnique: boolean | undefined = false;

      if (
        Object.keys(isConfigUniqueBody).length === 0 ||
        (isEditing && data?.data.items.find((t) => t.id === dialogId && t.name === isConfigUniqueBody.name))
      ) {
        if (isConfigUniqueBody.id) {
          clearErrors(['id']);
          clearErrors(['root.id']);
        }

        if (isConfigUniqueBody.name) {
          clearErrors(['name']);
          clearErrors(['root.name']);
        }
        return;
      }

      const isConfigUniqueRes = await isConfigUnique({ body: isConfigUniqueBody, isEditing });

      if (
        (
          isConfigUniqueRes as {
            error: FetchBaseQueryError | SerializedError;
          }
        ).error
      ) {
        // TODO - Handle the possible error
        return;
      }

      isNameUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isConfigUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.name
      );
      isIdUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isConfigUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.id
      );
      isNameUnique = (isNameUnique === undefined && true) || isNameUnique;
      isIdUnique = (isIdUnique === undefined && true) || isIdUnique;

      if (!isEditing && !isIdUnique) {
        setCustomError('id', CustomErrors.UNIQUE);
        setCustomError('root.id', CustomErrors.UNIQUE);
      }

      if (!isEditing && isIdUnique) {
        clearErrors(['id']);
        clearErrors(['root.id']);
      }

      if (!isNameUnique) {
        setCustomError('name', CustomErrors.UNIQUE);
        setCustomError('root.name', CustomErrors.UNIQUE);
      }

      if (isNameUnique) {
        clearErrors(['name']);
        clearErrors(['root.name']);
      }
    },
    onSubmit: async (id: string | boolean, data: any) => {
      const isEditing = typeof id === 'string';

      const submitData = { ...data };

      if (!submitData.editable) {
        submitData.editable = false;
      }

      if (submitData.type === PRIMITIVES.STRING) {
        submitData.string = submitData.string.split(',').map((el: string) => el.trim());
      }

      if (submitData.type !== PRIMITIVES.STRING && submitData.string) {
        delete submitData.string;
      }

      if (submitData.type !== PRIMITIVES.NUMBER) {
        delete submitData.fullRangeMin;
        delete submitData.fullRangeMax;
        delete submitData.safeRangeMin;
        delete submitData.safeRangeMax;
      }

      if (isEditing) {
        await putConfigTrigger({ id, body: submitData });
      } else {
        await postConfigTrigger(submitData);
      }
    },
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t(CHANNELS.CONFIGS),
    message: (extra: any) =>
      t('deleteMessage', {
        entity: t(CHANNELS.CONFIGS),
        name: extra.name,
        interpolation: { escapeValue: false },
      }) as string,
    onConfirm: (extra: any) => deleteConfigTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useConfigColumns(openDialog, confirm);

  return (
    <>
      <ConfigsTable
        title={t(CHANNELS.CONFIGS)}
        data={data?.data.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('config')}
        onResetStateButtonClick={openDialog}
        exportData={true}
        selection={true}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
