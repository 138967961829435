import { OTAReq, OTARes } from './typings';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import { useTranslation } from 'react-i18next';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { ApiResult, CustomErrors } from '@typings';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { CommonIsEntityUniqueRes } from '../../typings';
import { useLazyGetDeviceIdentitiesQuery } from '@/redux/api/system/deviceIdentitiesApiSlice';
import {
  useDeleteOTAMutation,
  useGetOTAListQuery,
  useIsOTAUniqueMutation,
  useLazyGetOTAQuery,
  usePostOTAMutation,
  usePutOTAMutation,
  useUploadBuildFileMutation,
} from '@/redux/api/admin/otaApiSlice';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import { useOTAColumns } from './useOTAColumns';
import useDownloadOTABuildFile from './useDownloadOTABuildFile';
import { isGreaterVersion, validateVersion } from '@/shared/validations';

export const OTATable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<OTARes>));

export default function OTAPanel() {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetOTAListQuery();
  const [getOTA] = useLazyGetOTAQuery();
  const [getAllDeviceIdentities] = useLazyGetDeviceIdentitiesQuery();
  const [isOTAUnique] = useIsOTAUniqueMutation();
  const [uploadBuildFile] = useUploadBuildFileMutation();
  const [postOTATrigger] = usePostOTAMutation();
  const [putOTATrigger] = usePutOTAMutation();
  const [deleteOTATrigger] = useDeleteOTAMutation();
  const { downloadOTABuildFile } = useDownloadOTABuildFile();

  const { AddEditDialog, openDialog } = useAddEditDialog<OTAReq>({
    title: t('OTA'),
    baseConfig: [
      {
        type: 'file',
        name: 'buildFile',
        placeholder: t('file'),
        options: {
          required: t('fieldRequiredError') as string,
        },
        fileConfig: {
          actions: [
            {
              condition: 'fileExist',
              label: t('download'),
              role: 'download',
              callback: (getValues: Function) => {
                downloadOTABuildFile(getValues('id'));
              },
            },
          ],
          accept:
            'application/octet-stream, application/mac-binary, ' +
            'application/macbinary, application/x-binary, application/x-macbinary, ' +
            'application/gzip, application/x-gzip, ',
        },
        helperText: t('acceptedTypes', {
          types:
            'application/octet-stream, application/mac-binary, ' +
            'application/macbinary, application/x-binary, application/x-macbinary ' +
            'application/gzip, application/x-gzip, ',
          interpolation: { escapeValue: false },
        }) as string,
      },
      {
        name: 'model',
        placeholder: t('deviceIdentity'),
        options: { required: t('fieldRequiredError') as string },
        type: 'autocomplete',
        selectConfig: {
          options: () => {
            return getAllDeviceIdentities().then((deviceIdentities) => {
              return (
                deviceIdentities.data?.data.items.map((di) => ({
                  label: `${di.name} (${di.id})`,
                  value: di.id,
                })) || []
              );
            });
          },
        },
      },
      {
        type: 'text',
        name: 'version',
        placeholder: t('version'),
        options: {
          required: t('fieldRequiredError') as string,
          validate: (value, formValues) => {
            return validateVersion(value) && formValues.minVersion
              ? isGreaterVersion(value, formValues.minVersion)
                ? true
                : (t('versionShouldBeGreaterThanMinVersion') as string)
              : true;
          },
          deps: ['minVersion', 'version'],
        },
      },
      {
        type: 'text',
        name: 'minVersion',
        placeholder: t('minVersion'),
        options: {
          required: t('fieldRequiredError') as string,
          validate: (value, formValues) =>
            !value || (validateVersion(value) && formValues.version)
              ? !isGreaterVersion(value, formValues.version)
                ? true
                : (t('versionMinShouldBeLessThanVersion') as string)
              : true,
          deps: ['minVersion', 'version'],
        },
      },
      {
        type: 'text',
        name: 'id',
        placeholder: t('buildFile'),
        options: {
          required: t('fieldRequiredError') as string,
        },
        needsAsyncValidation: true,
      },
    ],
    getEditData: async (dialogId: string | boolean) => {
      const res: any = await getOTA(dialogId as string);
      const ota: OTARes = { ...res.data?.data };
      return ota;
    },
    formValidation: async (formState, setCustomError, clearErrors, _, dialogId) => {
      const isEditing = typeof dialogId === 'string';
      const isOTAUniqueBody = {
        ...((formState.id && { id: formState.id || dialogId }) || {}),
      };

      let isOTAIdUnique: boolean | undefined = false;

      if (!isOTAUniqueBody?.id) {
        return;
      }

      if (isEditing && data?.data.items.find((OTA) => OTA.id === dialogId && OTA.id === isOTAUniqueBody.id)) {
        clearErrors(['id']);
        clearErrors(['root.id']);
        return;
      }

      if (isOTAUniqueBody.id && !formState?.buildFile) {
        setCustomError('id', t(CustomErrors.UPLOAD_FIRST));
        setCustomError('root.id', t(CustomErrors.UPLOAD_FIRST));
        return;
      }

      const isOTAUniqueRes = await isOTAUnique({ body: isOTAUniqueBody, isEditing });

      if (
        (
          isOTAUniqueRes as {
            error: FetchBaseQueryError | SerializedError;
          }
        ).error
      ) {
        // TODO - Handle the possible error
        return;
      }

      isOTAIdUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isOTAUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.id
      );
      isOTAIdUnique = (isOTAIdUnique === undefined && true) || isOTAIdUnique;

      if (!isOTAIdUnique) {
        setCustomError('id', CustomErrors.UNIQUE);
        setCustomError('root.id', CustomErrors.UNIQUE);
      }

      if (isOTAIdUnique) {
        clearErrors(['id']);
        clearErrors(['root.id']);
      }
    },
    onSubmit: async (dialogId: string | boolean, data: any) => {
      const isEditing = typeof dialogId === 'string';
      const getExtension = (data: any) => (data?.buildFile?.type?.includes('gzip') ? '.tar.gz' : '.bin');

      async function _uploadBuildFile(id: string) {
        const hasFiles = Object.values(data).some((value) => value instanceof File);
        if (hasFiles) {
          const form = new FormData();
          form.append('id', id + getExtension(data));
          form.append('model', data.model);
          form.append('version', data.version);
          form.append('buildFile', data.buildFile);
          return await uploadBuildFile(form);
        }
      }

      if (isEditing) {
        await putOTATrigger({ id: dialogId, body: data });
      } else {
        if (data.buildFile && data.buildFile instanceof File) {
          try {
            await _uploadBuildFile(data.id);
            await postOTATrigger({
              id: data.id + getExtension(data),
              model: data.model,
              version: data.version,
              minVersion: data?.minVersion || null,
            });
          } catch (e) {
            return;
          }
        }
      }
    },
  });

  const { ConfirmDialog: DeleteDialog, confirm: confirmDeletion } = useConfirmDialog({
    title: t('delete') + ' ' + t('brand'),
    message: (extra: any) => t('deleteMessage', { entity: t('brand'), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteOTATrigger(extra.id),
  });

  const columns = useOTAColumns(openDialog, confirmDeletion);

  return (
    <>
      <OTATable
        title={t('OTA')}
        data={data?.data.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('OTA')}
        onResetStateButtonClick={openDialog}
      />
      <DeleteDialog />
      <AddEditDialog />
    </>
  );
}
