import useEventsColumns from './useEventsColumns';
import {
  useDeleteEventMutation,
  useGetEventsQuery,
  useLazyGetEventQuery,
  usePutEventMutation,
  usePostEventMutation,
  useIsEventUniqueMutation,
} from '@/redux/api/system/eventsApiSlice';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { EventRes, EventReq } from './typings';
import { useTranslation } from 'react-i18next';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { ApiResult, CustomErrors } from '@/shared/typings';
import { isValidJSON } from '@/shared/utils';
import { CHANNELS, ROLES, SENDING_STYLE } from '@/shared/constants';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { CommonIsEntityUniqueRes } from '../../typings';

export const EventsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<EventRes>));

export default function EventsPanel() {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetEventsQuery();
  const [getEvent] = useLazyGetEventQuery();
  const [postEventTrigger] = usePostEventMutation();
  const [putEventTrigger] = usePutEventMutation();
  const [deleteEventTrigger] = useDeleteEventMutation();
  const [isEventUnique] = useIsEventUniqueMutation();

  const { AddEditDialog, openDialog } = useAddEditDialog<EventReq>({
    title: t('event'),
    baseConfig: [
      {
        name: 'name',
        placeholder: t('name'),
        options: { required: t('fieldRequiredError') as string },
        needsAsyncValidation: true,
      },
      {
        name: 'id',
        placeholder: t('key'),
        options: { required: t('fieldRequiredError') as string },
        needsAsyncValidation: true,
      },
      {
        name: 'description',
        placeholder: t('description'),
      },
      {
        type: 'autocomplete',
        name: 'sendingStyle',
        placeholder: t('sendingStyle'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(SENDING_STYLE).map((sendingStyle) => ({
            label: t(sendingStyle),
            value: sendingStyle,
          })),
        },
      },
      {
        type: 'autocomplete',
        name: 'role',
        placeholder: t('roles'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(ROLES).map((role) => ({ label: t(role), value: role })),
          multiple: true,
        },
      },
      {
        type: 'checkbox',
        name: 'parameters',
        placeholder: t('parameters'),
      },
      {
        name: 'payloadExample',
        placeholder: t('payloadExample'),
        options: {
          required: false,
          deps: ['parameters'],
          validate: (value, formValues) => {
            if (formValues.parameters) {
              return value && isValidJSON(value) ? true : (t('JSONInvalid') as string);
            }
            return true;
          },
        },
        helperText: t('JSONHelperText') as string,
      },
    ],
    getEditData: async (dialogId: string | boolean) => {
      const event: any = await getEvent(dialogId as string);
      return event.data?.data;
    },
    formValidation: async (formState, setCustomError, clearErrors, _, dialogId) => {
      const parameters = formState.parameters;
      if (!parameters) {
        setCustomError('payloadExample', CustomErrors.HIDE_FIELD);
      } else {
        clearErrors(['payloadExample']);
      }

      const isEditing = typeof dialogId === 'string';
      const isEventUniqueBody = {
        ...((formState.id && { id: formState.id || dialogId }) || {}),
        ...((formState.name && { name: formState.name }) || {}),
      };

      let isNameUnique: boolean | undefined = false;
      let isIdUnique: boolean | undefined = false;

      if (
        Object.keys(isEventUniqueBody).length === 0 ||
        (isEditing && data?.data.items.find((t) => t.id === dialogId && t.name === isEventUniqueBody.name))
      ) {
        if (isEventUniqueBody.id) {
          clearErrors(['id']);
          clearErrors(['root.id']);
        }

        if (isEventUniqueBody.name) {
          clearErrors(['name']);
          clearErrors(['root.name']);
        }
        return;
      }

      const isEventUniqueRes = await isEventUnique({ body: isEventUniqueBody, isEditing });

      if (
        (
          isEventUniqueRes as {
            error: FetchBaseQueryError | SerializedError;
          }
        ).error
      ) {
        // TODO - Handle the possible error
        return;
      }

      isNameUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isEventUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.name
      );
      isIdUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isEventUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.id
      );
      isNameUnique = (isNameUnique === undefined && true) || isNameUnique;
      isIdUnique = (isIdUnique === undefined && true) || isIdUnique;

      if (!isEditing && !isIdUnique) {
        setCustomError('id', CustomErrors.UNIQUE);
        setCustomError('root.id', CustomErrors.UNIQUE);
      }

      if (!isEditing && isIdUnique) {
        clearErrors(['id']);
        clearErrors(['root.id']);
      }

      if (!isNameUnique) {
        setCustomError('name', CustomErrors.UNIQUE);
        setCustomError('root.name', CustomErrors.UNIQUE);
      }

      if (isNameUnique) {
        clearErrors(['name']);
        clearErrors(['root.name']);
      }
    },
    onSubmit: async (dialogId: string | boolean, data) => {
      const isEditing = typeof dialogId === 'string';
      if (!data.parameters) {
        data.parameters = false;
      }
      if (!data.parameters) {
        delete data.payloadExample;
      }
      if (isEditing) {
        const id = dialogId;
        await putEventTrigger({ id, body: data });
      } else {
        await postEventTrigger(data);
      }
    },
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t('event'),
    message: (extra: any) => t('deleteMessage', { entity: t('event'), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteEventTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useEventsColumns(openDialog, confirm);

  return (
    <>
      <EventsTable
        title={t(CHANNELS.EVENTS)}
        data={data?.data.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('event')}
        onResetStateButtonClick={openDialog}
        exportData={true}
        selection={true}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
