import useAddEditDialog from '@/hooks/useAddEditDialog';
import {
  useDeleteReactionMutation,
  useGetReactionsQuery,
  useIsReactionUniqueMutation,
  usePostReactionMutation,
  usePutReactionMutation,
} from '@/redux/api/system/reactionsApiSlice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactionReq, ReactionRes } from './typings';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import useReactionColumns from './useReactionColumns';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import { CHANNELS, REACTION_KIND } from '@/shared/constants';
import { ApiResult, CustomErrors } from '@typings';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { CommonIsEntityUniqueRes } from '../../typings';

export const ReactionsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<ReactionRes>));

export default function ReactionsPanel() {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetReactionsQuery();
  const [postReactionTrigger] = usePostReactionMutation();
  const [putReactionTrigger] = usePutReactionMutation();
  const [deleteReactionTrigger] = useDeleteReactionMutation();
  const [isReactionUnique] = useIsReactionUniqueMutation();

  const { AddEditDialog, openDialog } = useAddEditDialog<ReactionReq>({
    title: t(CHANNELS.REACTIONS),
    baseConfig: [
      {
        name: 'name',
        placeholder: t('name'),
        options: { required: true },
        needsAsyncValidation: true,
      },
      {
        name: 'description',
        placeholder: t('description'),
      },
      {
        type: 'autocomplete',
        name: 'kind',
        placeholder: t('kind'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(REACTION_KIND).map((kind) => ({ label: t(kind), value: kind })),
        },
      },
      {
        name: 'destination',
        placeholder: t('destination'),
        helperText: t('commaSeparatedValuesHelperText', { subject: t('destination') }) as string,
      },
    ],
    getEditData: async (dialogId: string | boolean) => {
      const reaction = data?.data.items.find((reaction: any) => reaction.id === dialogId);
      return {
        ...reaction,
        ...(reaction?.destination ? { destination: (reaction.destination as string[]).join(', ') } : {}),
      };
    },
    formValidation: async (formState, setCustomError, clearErrors, _, dialogId) => {
      const isEditing = typeof dialogId === 'string';
      const isReactionUniqueBody = {
        ...((isEditing && { id: dialogId }) || {}),
        ...((formState.name && { name: formState.name }) || {}),
      };

      let isNameUnique: boolean | undefined = false;

      if (
        Object.keys(isReactionUniqueBody).length === 0 ||
        (isEditing && data?.data.items.find((t) => t.id === dialogId && t.name === isReactionUniqueBody.name))
      ) {
        if (isReactionUniqueBody.id) {
          clearErrors(['id']);
          clearErrors(['root.id']);
        }

        if (isReactionUniqueBody.name) {
          clearErrors(['name']);
          clearErrors(['root.name']);
        }
        return;
      }

      const isReactionUniqueRes = await isReactionUnique({ body: isReactionUniqueBody, isEditing });

      if (
        (
          isReactionUniqueRes as {
            error: FetchBaseQueryError | SerializedError;
          }
        ).error
      ) {
        // TODO - Handle the possible error
        return;
      }

      isNameUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isReactionUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.name
      );
      isNameUnique = (isNameUnique === undefined && true) || isNameUnique;

      if (!isNameUnique) {
        setCustomError('name', CustomErrors.UNIQUE);
        setCustomError('root.name', CustomErrors.UNIQUE);
      }

      if (isNameUnique) {
        clearErrors(['name']);
        clearErrors(['root.name']);
      }
    },
    onSubmit: async (dialogId: string | boolean, data) => {
      const isEditing = typeof dialogId === 'string';

      const submitData = {
        ...data,
        ...(data.destination ? { destination: (data.destination as unknown as string).split(',') } : {}),
      };

      if (isEditing) {
        await putReactionTrigger({
          id: dialogId,
          body: {
            ...data,
            ...(data.destination ? { destination: (data.destination as unknown as string).split(',') } : {}),
          },
        });
      } else {
        await postReactionTrigger(submitData);
      }
    },
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: `${t('delete')} ${t('reaction')}`,
    message: (extra: any) => t('deleteMessage', { entity: t('reaction'), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteReactionTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useReactionColumns(openDialog, confirm);

  return (
    <>
      <ReactionsTable
        title={t(CHANNELS.REACTIONS)}
        data={data?.data.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('reaction')}
        onResetStateButtonClick={openDialog}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
