import useTelemetryColumns from './useTelemetryColumns';
import {
  useDeleteTelemetryMutation,
  useGetTelemetryEntriesQuery,
  useLazyGetTelemetryEntryQuery,
  usePutTelemetryMutation,
  usePostTelemetryMutation,
  useIsTelemetryUniqueMutation,
} from '@/redux/api/system/telemetryApiSlice';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { TelemetryReq, TelemetryRes } from './typings';
import { useTranslation } from 'react-i18next';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import SI_UNITS from './si_units.json';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { ApiResult, CustomErrors } from '@typings';
import { ROLES, PRIMITIVES, TELEMETRY_SENDING_STYLE, SENDING_RULE, TELEMETRY_TYPE, CHANNELS } from '@/shared/constants';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { CommonIsEntityUniqueRes } from '../../typings';
import { capitalize } from '@mui/material';
import { isValidNumber } from '@/shared/utils';

export const TelemetryTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<TelemetryRes>));

export default function TelemetryPanel() {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetTelemetryEntriesQuery();

  const [getTelemetry] = useLazyGetTelemetryEntryQuery();
  const [postTelemetryTrigger] = usePostTelemetryMutation();
  const [putTelemetryTrigger] = usePutTelemetryMutation();
  const [deleteTelemetryTrigger] = useDeleteTelemetryMutation();
  const [isTelemetryUnique] = useIsTelemetryUniqueMutation();

  const { AddEditDialog, openDialog } = useAddEditDialog<TelemetryReq>({
    title: t(CHANNELS.TELEMETRY),
    baseConfig: [
      {
        name: 'name',
        placeholder: t('name'),
        options: {
          required: t('fieldRequiredError') as string,
        },
        needsAsyncValidation: true,
      },
      {
        name: 'id',
        placeholder: t('key'),
        options: {
          required: t('fieldRequiredError') as string,
        },
        needsAsyncValidation: true,
      },
      {
        name: 'description',
        placeholder: t('description'),
      },
      {
        type: 'autocomplete',
        name: 'type',
        placeholder: t('type'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(TELEMETRY_TYPE).map((value) => ({ label: t(value), value })),
        },
      },
      {
        type: 'autocomplete',
        name: 'primitive',
        placeholder: t('primitive'),
        options: { required: t('fieldRequiredError') as string, deps: ['unitOfMeasure'] },
        selectConfig: {
          options: Object.values(PRIMITIVES).map((primitive) => ({
            label: t(primitive),
            value: primitive,
          })),
        },
      },
      {
        type: 'autocomplete',
        name: 'unitOfMeasure',
        placeholder: t('unitOfMeasure'),
        options: {
          deps: ['primitive'],
          validate: (value, { primitive }) => {
            const isUnitOfMeasureRequired = primitive.value === PRIMITIVES.NUMBER;
            if (isUnitOfMeasureRequired) {
              return !value ? (t('fieldRequiredError') as string) : true;
            }
            return true;
          },
        },
        selectConfig: {
          options: SI_UNITS.map((unit) => ({ ...unit, label: `${unit.label} - ${unit.value}` })),
        },
      },
      {
        type: 'autocomplete',
        name: 'sendingStyle',
        placeholder: t('sendingStyle'),
        options: { required: t('fieldRequiredError') as string, deps: ['value'] },
        selectConfig: {
          options: Object.values(TELEMETRY_SENDING_STYLE).map((value) => ({ label: t(value), value })),
        },
      },
      {
        type: 'autocomplete',
        name: 'sendingRule',
        placeholder: t('sendingRule'),
        selectConfig: {
          options: Object.values(SENDING_RULE).map((value) => ({ label: t(value), value })),
        },
        options: {
          required: false,
          deps: ['sendingStyle'],
          validate: (value, formValues) => {
            if (
              formValues.sendingStyle.value === TELEMETRY_SENDING_STYLE.ON_THRESHOLD ||
              formValues.sendingStyle.value === TELEMETRY_SENDING_STYLE.ON_DELTA
            ) {
              return value && value !== '' ? true : (t('fieldRequiredError') as string);
            }
            return true;
          },
        },
      },
      {
        name: 'value',
        placeholder: t('value'),
        type: 'number',
        options: {
          required: false,
          deps: ['sendingStyle'],
          validate: (value, formValues) => {
            if (
              [TELEMETRY_SENDING_STYLE.ON_THRESHOLD, TELEMETRY_SENDING_STYLE.ON_DELTA].some(
                (sendingStyle) => sendingStyle === formValues.sendingStyle.value
              )
            ) {
              return !isValidNumber(value) ? (t('fieldNumberError') as string) : true;
            }
            return true;
          },
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        type: 'autocomplete',
        name: 'role',
        placeholder: t('roles'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(ROLES).map((role) => ({ label: t(role), value: role })),
          multiple: true,
        },
      },
      {
        name: 'triageRangeMin',
        placeholder: t('triageRangeMin'),
        type: 'number',
        options: {
          required: false,
          deps: ['sendingStyle', 'primitive'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'triageRangeMax',
        placeholder: t('triageRangeMax'),
        type: 'number',
        options: {
          required: false,
          deps: ['sendingStyle', 'primitive'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        type: 'autocomplete',
        name: 'triageExpectedBooleanValue',
        placeholder: t('triageExpectedValue'),
        options: {
          required: false,
          deps: ['primitive'],
        },
        selectConfig: {
          options: [true, false].map((v) => ((value) => ({ label: capitalize(value), value }))(String(v))),
        },
      },
      {
        name: 'triageExpectedStringValue',
        placeholder: t('triageExpectedValue'),
        options: {
          required: false,
          deps: ['primitive'],
        },
      },
      {
        type: 'checkbox',
        name: 'alertIntegration',
        placeholder: t('alertIntegration'),
        options: {
          deps: ['triageRangeMin', 'triageRangeMax', 'triageExpectedBooleanValue', 'triageExpectedStringValue'],
        },
      },
      {
        name: 'tags',
        placeholder: t('tags'),
        options: {
          required: false,
        },
        helperText: t('commaSeparatedValuesHelperText', { subject: t('tags') }) as string,
      },
    ],
    getEditData: async (dialogId: string | boolean) => {
      const telemetry: any = await getTelemetry(dialogId as string);

      return { ...telemetry.data?.data, tags: telemetry.data?.data.tags?.join(', ') };
    },
    formValidation: async (formState, setCustomError, clearErrors, errors, dialogId) => {
      const isEditing = typeof dialogId === 'string';
      const sendingStyle = formState.sendingStyle?.value;
      const primitive = formState.primitive?.value;
      const triageRangeMin = formState.triageRangeMin;
      const triageRangeMax = formState.triageRangeMax;
      const triageExpectedBooleanValue = formState.triageExpectedBooleanValue?.value;
      const triageExpectedStringValue = formState.triageExpectedStringValue;

      if (
        !sendingStyle ||
        sendingStyle === TELEMETRY_SENDING_STYLE.ALWAYS ||
        sendingStyle === TELEMETRY_SENDING_STYLE.ON_CHANGE
      ) {
        setCustomError('sendingRule', CustomErrors.HIDE_FIELD);
        setCustomError('value', CustomErrors.HIDE_FIELD);
      } else {
        errors?.sendingRule?.message === CustomErrors.HIDE_FIELD && clearErrors(['sendingRule']);
        errors?.value?.message === CustomErrors.HIDE_FIELD && clearErrors(['value']);
        isEditing && !Object.keys(errors)?.length && clearErrors(['value', 'sendingRule']);
      }

      if (!primitive) {
        setCustomError('triageRangeMax', CustomErrors.HIDE_FIELD);
        setCustomError('triageRangeMin', CustomErrors.HIDE_FIELD);
        setCustomError('triageExpectedBooleanValue', CustomErrors.HIDE_FIELD);
        setCustomError('triageExpectedStringValue', CustomErrors.HIDE_FIELD);
      } else if (primitive === PRIMITIVES.NUMBER) {
        clearErrors(['triageRangeMax', 'triageRangeMin']);
        setCustomError('triageExpectedBooleanValue', CustomErrors.HIDE_FIELD);
        setCustomError('triageExpectedStringValue', CustomErrors.HIDE_FIELD);
      } else if (primitive === PRIMITIVES.BOOLEAN) {
        clearErrors(['triageExpectedBooleanValue']);
        setCustomError('triageRangeMax', CustomErrors.HIDE_FIELD);
        setCustomError('triageRangeMin', CustomErrors.HIDE_FIELD);
        setCustomError('triageExpectedStringValue', CustomErrors.HIDE_FIELD);
      } else if (primitive === PRIMITIVES.STRING) {
        clearErrors(['triageExpectedStringValue']);
        setCustomError('triageRangeMax', CustomErrors.HIDE_FIELD);
        setCustomError('triageRangeMin', CustomErrors.HIDE_FIELD);
        setCustomError('triageExpectedBooleanValue', CustomErrors.HIDE_FIELD);
      }

      if (triageRangeMin || triageRangeMax || triageExpectedBooleanValue || triageExpectedStringValue) {
        clearErrors(['alertIntegration']);
      } else {
        setCustomError('alertIntegration', CustomErrors.HIDE_FIELD);
      }

      const isTelemetryUniqueBody = {
        ...((formState.id && { id: formState.id || dialogId }) || {}),
        ...((formState.name && { name: formState.name }) || {}),
      };

      let isNameUnique: boolean | undefined = false;
      let isIdUnique: boolean | undefined = false;

      if (
        Object.keys(isTelemetryUniqueBody).length === 0 ||
        (isEditing && data?.data.items.find((t) => t.id === dialogId && t.name === isTelemetryUniqueBody.name))
      ) {
        if (isTelemetryUniqueBody.id) {
          clearErrors(['id']);
          clearErrors(['root.id']);
        }

        if (isTelemetryUniqueBody.name) {
          clearErrors(['name']);
          clearErrors(['root.name']);
        }
        return;
      }

      const isTelemetryUniqueRes = await isTelemetryUnique({ body: isTelemetryUniqueBody, isEditing });

      if (
        (
          isTelemetryUniqueRes as {
            error: FetchBaseQueryError | SerializedError;
          }
        ).error
      ) {
        // TODO - Handle the possible error
        return;
      }

      isNameUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isTelemetryUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.name
      );
      isIdUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isTelemetryUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.id
      );
      isNameUnique = (isNameUnique === undefined && true) || isNameUnique;
      isIdUnique = (isIdUnique === undefined && true) || isIdUnique;

      if (!isEditing && !isIdUnique) {
        setCustomError('id', CustomErrors.UNIQUE);
        setCustomError('root.id', CustomErrors.UNIQUE);
      }

      if (!isEditing && isIdUnique) {
        clearErrors(['id']);
        clearErrors(['root.id']);
      }

      if (!isNameUnique) {
        setCustomError('name', CustomErrors.UNIQUE);
        setCustomError('root.name', CustomErrors.UNIQUE);
      }

      if (isNameUnique) {
        clearErrors(['name']);
        clearErrors(['root.name']);
      }
    },
    onSubmit: async (dialogId: string | boolean, data) => {
      const isEditing = typeof dialogId === 'string';

      const triageFields = [
        (data as any).triageRangeMin,
        (data as any).triageRangeMax,
        (data as any).triageExpectedBooleanValue,
        (data as any).triageExpectedStringValue,
      ];

      const hasTriageFields = triageFields.some((field) => field !== undefined && field !== null && field !== '');

      if (data.alertIntegration && hasTriageFields) {
        data.alertIntegration = true;
      } else {
        data.alertIntegration = false;
      }

      if (
        !data.sendingStyle ||
        data.sendingStyle === TELEMETRY_SENDING_STYLE.ALWAYS ||
        data.sendingStyle === TELEMETRY_SENDING_STYLE.ON_CHANGE
      ) {
        delete data.sendingRule;
        delete data.value;
      }
      if (PRIMITIVES.BOOLEAN === data.primitive || PRIMITIVES.STRING === data.primitive) {
        delete (data as any).triageRangeMax;
        delete (data as any).triageRangeMin;
        data.triageExpectedValue = (data as any).triageExpectedBooleanValue || (data as any).triageExpectedStringValue;
        if (PRIMITIVES.BOOLEAN === data.primitive) {
          delete (data as any).triageExpectedBooleanValue;
        }
        if (PRIMITIVES.STRING === data.primitive) {
          delete (data as any).triageExpectedStringValue;
        }
      }
      if (data.primitive === PRIMITIVES.NUMBER) {
        delete (data as any).triageExpectedBooleanValue;
        delete (data as any).triageExpectedStringValue;
      }

      if (data.tags) {
        data.tags = (data.tags as unknown as string).split(', ');
      }
      if (isEditing) {
        const id = dialogId;
        await putTelemetryTrigger({ id, body: data });
      } else {
        await postTelemetryTrigger(data);
      }
    },
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t(CHANNELS.TELEMETRY),
    message: (extra: any) => t('deleteMessage', { entity: t(CHANNELS.TELEMETRY), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteTelemetryTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useTelemetryColumns(openDialog, confirm);

  return (
    <>
      <TelemetryTable
        title={t(CHANNELS.TELEMETRY)}
        data={data?.data.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t(CHANNELS.TELEMETRY)}
        onResetStateButtonClick={openDialog}
        exportData={true}
        selection={true}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
