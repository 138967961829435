import { ApiResult } from '@typings';
import { AlarmReq, AlarmRes } from '@/components/OneTools/Channels/Alarms/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { CommonIsEntityUniqueReq, CommonIsEntityUniqueRes } from '@/components/OneTools/typings';
import { dispatchPendingToOperationsSlice } from '../dispatchPendingToOperations';
import { CHANNELS } from '@/shared/constants';

const URL_PREFIX = `${API_VERSION.v2}/system/alarms`;

export const AlarmsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Alarms'] }).injectEndpoints({
  endpoints: (builder) => ({
    getAlarms: builder.query<ApiResult<{ items: AlarmRes[] }>, void>({
      query: () => URL_PREFIX + '/list',
      providesTags: ['Alarms'],
    }),
    getAlarm: builder.query<ApiResult<AlarmRes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      providesTags: ['Alarms'],
    }),
    isAlarmUnique: builder.mutation<ApiResult<CommonIsEntityUniqueRes>, CommonIsEntityUniqueReq>({
      query: ({ body, isEditing }: CommonIsEntityUniqueReq) => ({
        url: `${URL_PREFIX}/unique`,
        method: 'POST',
        body: { ...body },
        params: { isEditing },
      }),
    }),
    postAlarm: builder.mutation<ApiResult<AlarmRes>, AlarmReq>({
      query: (body: AlarmReq) => ({
        url: URL_PREFIX,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Alarms'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'POST',
          subject: arg.name,
          entity: CHANNELS.ALARMS,
        });
      },
    }),
    putAlarm: builder.mutation<ApiResult<AlarmRes>, { id: string; body: AlarmReq }>({
      query: ({ id, body }: { id: string; body: AlarmReq }) => ({
        url: URL_PREFIX,
        method: 'PUT',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['Alarms'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PUT',
          subject: arg.body.name,
          entity: CHANNELS.ALARMS,
        });
      },
    }),
    patchAlarm: builder.mutation<ApiResult<AlarmRes>, { id: string; body: Partial<AlarmReq>; name: string }>({
      query: ({ id, body }) => ({
        url: URL_PREFIX,
        method: 'PATCH',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['Alarms'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PATCH',
          subject: arg.name,
          entity: CHANNELS.ALARMS,
        });
      },
    }),
    deleteAlarm: builder.mutation<ApiResult<null>, { id: string; name: string }>({
      query: ({ id }: { id: string; name: string }) => ({
        url: URL_PREFIX,
        method: 'DELETE',
        params: { id },
      }),
      invalidatesTags: ['Alarms'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'DELETE',
          subject: arg.name,
          entity: CHANNELS.ALARMS,
        });
      },
    }),
  }),
});

export const {
  useLazyGetAlarmsQuery,
  useGetAlarmsQuery,
  useLazyGetAlarmQuery,
  useIsAlarmUniqueMutation,
  usePostAlarmMutation,
  usePutAlarmMutation,
  usePatchAlarmMutation,
  useDeleteAlarmMutation,
} = AlarmsApiSlice;
