import { ApiResult } from '@typings';
import { CommandReq, CommandRes } from '@/components/OneTools/Channels/Commands/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { CommonIsEntityUniqueRes, CommonIsEntityUniqueReq } from '@/components/OneTools/typings';
import { dispatchPendingToOperationsSlice } from '../dispatchPendingToOperations';
import { CHANNELS } from '@/shared/constants';

const URL_PREFIX = `${API_VERSION.v2}/system/commands`;

export const commandsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Commands'] }).injectEndpoints({
  endpoints: (builder) => ({
    getCommands: builder.query<ApiResult<{ items: CommandRes[] }>, void>({
      query: () => URL_PREFIX + '/list',
      providesTags: ['Commands'],
    }),
    getCommand: builder.query<ApiResult<CommandRes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      providesTags: ['Commands'],
    }),
    isCommandUnique: builder.mutation<ApiResult<CommonIsEntityUniqueRes>, CommonIsEntityUniqueReq>({
      query: ({ body, isEditing }: CommonIsEntityUniqueReq) => ({
        url: `${URL_PREFIX}/unique`,
        method: 'POST',
        body: { ...body },
        params: { isEditing },
      }),
    }),
    postCommand: builder.mutation<ApiResult<CommandRes>, CommandReq>({
      query: (body: CommandReq) => ({
        url: URL_PREFIX,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Commands'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'POST',
          subject: arg.name,
          entity: CHANNELS.COMMANDS,
        });
      },
    }),
    putCommand: builder.mutation<ApiResult<CommandRes>, { id: string; body: CommandReq }>({
      query: ({ id, body }: { id: string; body: CommandReq }) => ({
        url: URL_PREFIX,
        method: 'PUT',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['Commands'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PUT',
          subject: arg.body.name,
          entity: CHANNELS.COMMANDS,
        });
      },
    }),
    patchCommand: builder.mutation<ApiResult<CommandRes>, { id: string; body: Partial<CommandReq>; name: string }>({
      query: ({ id, body }) => ({
        url: URL_PREFIX,
        method: 'PATCH',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['Commands'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PATCH',
          subject: arg.name,
          entity: CHANNELS.COMMANDS,
        });
      },
    }),
    deleteCommand: builder.mutation<ApiResult<null>, { id: string; name: string }>({
      query: ({ id }: { id: string; name: string }) => ({
        url: URL_PREFIX,
        method: 'DELETE',
        params: { id },
      }),
      invalidatesTags: ['Commands'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'DELETE',
          subject: arg.name,
          entity: CHANNELS.COMMANDS,
        });
      },
    }),
  }),
});

export const {
  useLazyGetCommandsQuery,
  useGetCommandsQuery,
  useGetCommandQuery,
  useLazyGetCommandQuery,
  useIsCommandUniqueMutation,
  usePostCommandMutation,
  usePutCommandMutation,
  usePatchCommandMutation,
  useDeleteCommandMutation,
} = commandsApiSlice;
