import useAddEditDialog from '@/hooks/useAddEditDialog';
import {
  useDeleteConsumableMutation,
  useGetConsumablesQuery,
  useIsConsumableUniqueMutation,
  usePostConsumableMutation,
  usePutConsumableMutation,
} from '@/redux/api/system/consumablesApiSlice';
import { useTranslation } from 'react-i18next';
import { ConsumableReq, ConsumableRes } from './typings';
import useConsumablesColumns from './useConsumablesColumns';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { CHANNELS, CONSUMABLE_KIND } from '@/shared/constants';
import { ApiResult, CustomErrors } from '@typings';
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { CommonIsEntityUniqueRes } from '../../typings';

export const ConsumablesTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<ConsumableRes>));

export default function ConsumablesPanel() {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetConsumablesQuery();
  const [postConsumableTrigger] = usePostConsumableMutation();
  const [putConsumableTrigger] = usePutConsumableMutation();
  const [deleteConsumableTrigger] = useDeleteConsumableMutation();
  const [isConsumableUnique] = useIsConsumableUniqueMutation();

  const { AddEditDialog, openDialog } = useAddEditDialog<ConsumableReq>({
    title: t(CHANNELS.CONSUMABLES),
    baseConfig: [
      {
        name: 'name',
        placeholder: t('name'),
        options: { required: t('fieldRequiredError') as string },
        needsAsyncValidation: true,
      },
      {
        name: 'id',
        placeholder: t('key'),
        options: { required: t('fieldRequiredError') as string },
        needsAsyncValidation: true,
      },
      {
        name: 'description',
        placeholder: t('description'),
      },
      {
        type: 'autocomplete',
        name: 'kind',
        placeholder: t('type'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(CONSUMABLE_KIND).map((consumable) => ({ label: t(consumable), value: consumable })),
        },
      },
    ],
    getEditData: async (dialogId: string | boolean) =>
      data?.data.items?.find((consumable: any) => consumable.id === dialogId),
    formValidation: async (formState, setCustomError, clearErrors, _, dialogId) => {
      const isEditing = typeof dialogId === 'string';
      const isConsumableUniqueBody = {
        ...((formState.id && { id: formState.id || dialogId }) || {}),
        ...((formState.name && { name: formState.name }) || {}),
      };

      let isNameUnique: boolean | undefined = false;
      let isIdUnique: boolean | undefined = false;

      if (
        Object.keys(isConsumableUniqueBody).length === 0 ||
        (isEditing && data?.data.items.find((t) => t.id === dialogId && t.name === isConsumableUniqueBody.name))
      ) {
        if (isConsumableUniqueBody.id) {
          clearErrors(['id']);
          clearErrors(['root.id']);
        }

        if (isConsumableUniqueBody.name) {
          clearErrors(['name']);
          clearErrors(['root.name']);
        }
        return;
      }

      const isConsumableUniqueRes = await isConsumableUnique({ body: isConsumableUniqueBody, isEditing });
      if (
        (
          isConsumableUniqueRes as {
            error: FetchBaseQueryError | SerializedError;
          }
        ).error
      ) {
        // TODO - Handle the possible error
        return;
      }

      isNameUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isConsumableUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.name
      );
      isIdUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isConsumableUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.id
      );
      isNameUnique = (isNameUnique === undefined && true) || isNameUnique;
      isIdUnique = (isIdUnique === undefined && true) || isIdUnique;

      if (!isEditing && !isIdUnique) {
        setCustomError('id', CustomErrors.UNIQUE);
        setCustomError('root.id', CustomErrors.UNIQUE);
      }

      if (!isEditing && isIdUnique) {
        clearErrors(['id']);
        clearErrors(['root.id']);
      }

      if (!isNameUnique) {
        setCustomError('name', CustomErrors.UNIQUE);
        setCustomError('root.name', CustomErrors.UNIQUE);
      }

      if (isNameUnique) {
        clearErrors(['name']);
        clearErrors(['root.name']);
      }
    },
    onSubmit: async (dialogId: string | boolean, data) => {
      const isEditing = typeof dialogId === 'string';

      const submitData = { ...data };

      if (isEditing) {
        await putConsumableTrigger({ id: dialogId, body: data });
      } else {
        await postConsumableTrigger(submitData);
      }
    },
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t('consumable'),
    message: (extra: any) => t('deleteMessage', { entity: t('consumable'), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteConsumableTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useConsumablesColumns(openDialog, confirm);

  return (
    <>
      <ConsumablesTable
        title={t(CHANNELS.CONSUMABLES)}
        data={data?.data.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('consumable')}
        onResetStateButtonClick={openDialog}
        exportData={true}
        selection={true}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
