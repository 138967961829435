import { Typography, Paper, Grid, Box, Button, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme, alpha, keyframes, styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import CropFreeIcon from '@mui/icons-material/CropFree';
import RepeatIcon from '@mui/icons-material/Repeat';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import FilterCenterFocusOutlinedIcon from '@mui/icons-material/FilterCenterFocusOutlined';
import InvertColorsOutlinedIcon from '@mui/icons-material/InvertColorsOutlined';
import InvertColorsOffOutlinedIcon from '@mui/icons-material/InvertColorsOffOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import ExtensionIcon from '@mui/icons-material/Extension';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import ScienceIcon from '@mui/icons-material/Science';
import FlareIcon from '@mui/icons-material/Flare';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import AnimationIcon from '@mui/icons-material/Animation';
import Co2Icon from '@mui/icons-material/Co2';
import OpacityIcon from '@mui/icons-material/Opacity';
import TagIcon from '@mui/icons-material/Tag';
import dayjs from 'dayjs';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import { useParams } from 'react-router';
import { DeviceConsumable } from '../../DevicesPanel/typings';
import { CONSUMABLE_KIND } from '@/shared/constants';
import {
  usePatchDepleteConsumableMutation,
  usePatchRemoveConsumableMutation,
  usePatchSetConsumableMutation,
} from '@/redux/api/fleet/consumablesApiSlice';
import { useLazyGetConsumableSubsetsQuery } from '@/redux/api/system/consumableSubsetsApiSlice';
import LoadingFeedback from './LoadingFeedback';
import ErrorFeedback from './ErrorFeedback';
import SuccessFeedback from './SuccessFeedback';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { yellow } from '@mui/material/colors';
import { AddEditDialogConfigs } from '@/components/Shared/AddEditDialog/typings';
import AreaContainer, { AreaBody, AreaHeader, AreaRow } from '@/components/Shared/Card/Area';
import { Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import Area from '@/components/Shared/Card/Area';

const CardSlot = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.grayShades[0],
  borderRadius: theme.shape.borderRadius * 1.5,
  padding: theme.spacing(2),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const consumableKindIcons = {
  chemicals: <ScienceIcon />,
  CO2: <Co2Icon />,
  enhancements: <AutoAwesomeIcon />,
  filter: <PhotoFilterIcon />,
  membranes: <AnimationIcon />,
  ozone: <GasMeterIcon />,
  sanitizationKit: <SanitizerIcon />,
  uvcLight: <FlareIcon />,
  waterBottles: <OpacityIcon />,
};

const boxShadowAnimation = (theme: Theme) => keyframes`
0% {
  box-shadow: 0px 0px 5px ${alpha(theme.palette.error.light, 0.2)};
}
50% {
  box-shadow: 0px 0px 10px ${alpha(theme.palette.error.light, 0.5)};
}
100% {
  box-shadow: 0px 0px 5px ${alpha(theme.palette.error.light, 0.2)};
}
`;

const shakeAnimation = (theme: Theme) => keyframes`
0% { transform: translateX(0) }
12.5% { transform: translateX(3px) }
25% { transform: translateX(-3px) }
37.5% { transform: translateX(3px) }
50% { transform: translateX(0) }
100% { transform: translateX(0) }
`;

const ACTION_STATUS = {
  Uninitialized: 'uninitialized',
  Loading: 'loading',
  Error: 'error',
  Success: 'success',
} as const;

const ACTIONS = {
  None: 'none',
  Fill: 'fill',
  Set: 'set',
  Deplete: 'deplete',
  Remove: 'remove',
} as const;

const round = (val: number, precision: number): number => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(val * multiplier) / multiplier;
};

export const numberValueCircularBar = (percentageFill: number, consumable: DeviceConsumable) => {
  const subsetData = consumable.subset;

  if (percentageFill === 100) {
    return subsetData?.rangeFullCapacity;
  } else if (percentageFill === 0) {
    return subsetData?.rangeExhaust;
  } else {
    if (percentageFill < 10) {
      return round(consumable.current, 3);
    }
    return round(consumable.current, 2);
  }
};

export default function DeviceConsumableCard({
  consumable,
  slotCount,
  deviceStatus,
}: {
  consumable: DeviceConsumable;
  slotCount: number;
  deviceStatus: boolean;
}) {
  const { t } = useTranslation();
  const [patchSetConsumable, patchSetConsumableFlags] = usePatchSetConsumableMutation();
  const [patchRemoveConsumable, patchRemoveConsumableFlags] = usePatchRemoveConsumableMutation();
  const [patchDepleteConsumable, patchDepleteConsumableFlags] = usePatchDepleteConsumableMutation();

  const { deviceId } = useParams();

  const consumableData = consumable.consumable;
  const subsetData = consumable.subset;

  const [getConsumableSubsets] = useLazyGetConsumableSubsetsQuery();

  const [heightWhiteCard, setHeightWhiteCard] = useState<number>(0);
  const [heightGreyCard, setHeightGreyCard] = useState<number>(0);

  const ref = useRef<HTMLInputElement>(null);
  const refGrey = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      setHeightWhiteCard(ref.current.clientHeight);
    }
    if (refGrey.current) {
      setHeightGreyCard(refGrey.current.clientHeight);
    }
  }, [heightWhiteCard, heightGreyCard]);

  const normalise = (value: number, min: number, max: number) => Math.round(((value - min) * 100) / (max - min || 1));
  const fillPercentage = normalise(
    consumable.current || 0,
    subsetData?.rangeExhaust ? subsetData?.rangeExhaust : 0,
    subsetData?.rangeFullCapacity ? subsetData?.rangeFullCapacity : 100
  );

  const [percentageFill, setPercentageFill] = useState<number>(fillPercentage);
  const [empty, setRemove] = useState<boolean>(false);

  const iconPerKind = (kind: any) => {
    if (kind in consumableKindIcons) {
      return consumableKindIcons[kind as keyof typeof consumableKindIcons];
    } else {
      return <ExtensionIcon style={{ fontSize: '12px' }} />;
    }
  };

  const today = dayjs();
  const end = dayjs(consumable.expiresAt);
  const start = subsetData?.lifespan ? end.subtract(subsetData?.lifespan || 0, 'day') : dayjs(consumable.installedAt);
  const differenceInDays = today.diff(start, 'day');
  const lifespan = subsetData?.lifespan || end.diff(start, 'day');

  const expirationDate = dayjs(consumable.expiresAt).format('L');

  const expirationPercentage = (100 * (differenceInDays || 1)) / (subsetData?.lifespan || lifespan || 1);
  const safeExpirationPercentage = Math.max(0, Math.min(100, expirationPercentage));
  const isExpired = end.isBefore(today);

  const calcDot = `${safeExpirationPercentage}%`;

  const [performedAction, setPerformedAction] = useState<string | undefined>();

  const [actionStatus, setActionStatus] = useState<(typeof ACTION_STATUS)[keyof typeof ACTION_STATUS]>(
    ACTION_STATUS.Uninitialized
  );
  const [lastAction, setLastAction] = useState<(typeof ACTIONS)[keyof typeof ACTIONS]>(ACTIONS.None);

  useEffect(() => {
    if (lastAction === ACTIONS.Deplete) {
      setActionStatus(
        (patchDepleteConsumableFlags.isLoading && ACTION_STATUS.Loading) ||
          (patchDepleteConsumableFlags.isError && ACTION_STATUS.Error) ||
          (patchDepleteConsumableFlags.isSuccess && ACTION_STATUS.Success) ||
          ACTION_STATUS.Uninitialized
      );
    }
    if (lastAction === ACTIONS.Fill || lastAction === ACTIONS.Set) {
      setActionStatus(
        (patchSetConsumableFlags.isLoading && ACTION_STATUS.Loading) ||
          (patchSetConsumableFlags.isError && ACTION_STATUS.Error) ||
          (patchSetConsumableFlags.isSuccess && ACTION_STATUS.Success) ||
          ACTION_STATUS.Uninitialized
      );
    }
    if (lastAction === ACTIONS.Remove) {
      setActionStatus(
        (patchRemoveConsumableFlags.isLoading && ACTION_STATUS.Loading) ||
          (patchRemoveConsumableFlags.isError && ACTION_STATUS.Error) ||
          (patchRemoveConsumableFlags.isSuccess && ACTION_STATUS.Success) ||
          ACTION_STATUS.Uninitialized
      );
    }
  }, [patchSetConsumableFlags, patchRemoveConsumableFlags, patchDepleteConsumableFlags, lastAction]);

  const { AddEditDialog, openDialog } = useAddEditDialog({
    title: t('consumableSubsets'),
    baseConfig: [
      {
        name: 'subsetId',
        placeholder: t('consumableSubset'),
        options: { required: t('fieldRequiredError') as string },
        type: 'autocomplete',
        mutations: {
          onChange: {
            impacts: {
              fields: [
                {
                  name: 'monthsUntilExpiration',
                  handler: async ({ getValues, setValue }) => {
                    const value = getValues('subsetId');
                    const subsets = await getConsumableSubsets(null, true);
                    const subset = subsets.data?.data.items.find((s: any) => s.id === value.value);

                    setValue('monthsUntilExpiration', subset?.lifespan ? Math.floor(subset?.lifespan / 30) : undefined);
                    setValue(
                      'litersUntilExpiration',
                      subset?.rangeFullCapacity ? Math.floor(subset?.rangeFullCapacity) : undefined
                    );
                  },
                },
              ],
            },
          },
        },
        selectConfig: {
          options: () => {
            return getConsumableSubsets(null, true).then((subsets) => {
              return (subsets.data?.data?.items.filter((data) => data?.consumable === consumableData.id) || []).map(
                (s: any) => ({ label: s.name, value: s.id })
              );
            });
          },
        },
      },
      {
        name: 'monthsUntilExpiration',
        type: 'number',
        placeholder: t('monthsUntilExpiration'),
        options: {
          required: t('fieldRequiredError') as string,
        },
        domain: {
          min: 0,
        },
      },
      {
        name: 'litersUntilExpiration',
        type: 'number',
        placeholder: t('litersUntilExpiration'),
        options: {
          required: t('fieldRequiredError') as string,
        },
        domain: {
          min: 0,
        },
      },
      {
        type: 'date',
        name: 'expiresAt',
        options: { required: t('fieldRequiredError') as string },
        helperText: t('selectDate') as string,
      },
      {
        name: 'batch',
        placeholder: t('batch'),
        options: {
          required: (t('fieldRequiredError') as string) ? consumableData.kind === CONSUMABLE_KIND.ENHANCEMENTS : false,
        },
      },
    ],
    mutations: {
      onMount: {
        impacts: {
          form: {
            handler: (formReturn, initialConfig) => {
              if (consumable.consumable.kind === CONSUMABLE_KIND.FILTER) {
                const updatedConfig = initialConfig.reduce<AddEditDialogConfigs>((acc, curr) => {
                  if (curr.name === 'expiresAt') {
                    return acc;
                  }

                  return [...acc, curr];
                }, []);

                return updatedConfig;
              }

              if (consumable.consumable.kind !== CONSUMABLE_KIND.FILTER) {
                const updatedConfig = initialConfig.reduce<AddEditDialogConfigs>((acc, curr) => {
                  if (curr.name === 'monthsUntilExpiration' || curr.name === 'litersUntilExpiration') {
                    return acc;
                  }

                  return [...acc, curr];
                }, []);

                return updatedConfig;
              }

              return null;
            },
          },
        },
      },
    },
    onSubmit: async (_, data: any) => {
      const submitData = { ...data };

      submitData.expiresAt = dayjs(submitData.expiresAt)
        .add(submitData.monthsUntilExpiration || 0, 'months')
        .valueOf();
      submitData.index = consumable.index;
      submitData.rangeFullCapacity = submitData.litersUntilExpiration;

      submitData.serialNumber = deviceId;

      const subsets = await getConsumableSubsets(null, true);
      const name = subsets.data?.data.items.find((s) => s.id === submitData.subsetId)?.name;

      delete submitData.monthsUntilExpiration;
      delete submitData.litersUntilExpiration;

      setLastAction(ACTIONS.Set);
      setActionStatus(ACTION_STATUS.Loading);
      setPerformedAction(t('settingConsumableXXX', { number: submitData.index, name }) as string);
      await patchSetConsumable({ serialNumber: deviceId!, body: { ...submitData, installedAt: dayjs().valueOf() } });
    },
  });

  const { AddEditDialog: ReplenishDialog, openDialog: openReplenishDialog } = useAddEditDialog({
    title: t('consumableSubsets'),
    baseConfig: [
      {
        type: 'date',
        name: 'expiresAt',
        options: { required: t('fieldRequiredError') as string },
        helperText: t('selectDate') as string,
      },
      {
        name: 'batch',
        placeholder: t('batch'),
        options: {
          required: (t('fieldRequiredError') as string) ? consumableData.kind === CONSUMABLE_KIND.ENHANCEMENTS : false,
        },
      },
    ],
    getEditData: async () => {
      if (subsetData?.lifespan) {
        return {
          date: dayjs().add(subsetData?.lifespan, 'month').valueOf(),
        };
      }

      return {};
    },
    onSubmit: async (dialogId: string | boolean, data: any) => {
      const submitData = { ...data };

      submitData.expiresAt = dayjs(submitData.expiresAt).valueOf();
      submitData.index = consumable.index;

      submitData.serialNumber = deviceId;

      submitData.subsetId = subsetData?.id;

      const subsets = await getConsumableSubsets(null, true);
      const name = subsets.data?.data.items.find((s) => s.id === submitData.subsetId)?.name;

      setLastAction(ACTIONS.Fill);
      setActionStatus(ACTION_STATUS.Loading);
      setPerformedAction(t('replenishingConsumableXXX', { number: submitData.index, name }) as string);
      const res = await patchSetConsumable({
        serialNumber: deviceId!,
        body: { ...submitData, installedAt: dayjs().valueOf() },
      });
      !(res as { error: FetchBaseQueryError | SerializedError }).error && setPercentageFill(100);
    },
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('empty') + ' ' + t('consumableSubsets'),
    message: (extra: any) => t('deleteMessage', { entity: t('consumableSubsets'), name: subsetData?.name }) as string,
    onConfirm: async (extra: any) => {
      setLastAction(ACTIONS.Remove);
      setActionStatus('loading');
      setPerformedAction(t('removingConsumable', { number: extra.index, name: subsetData?.name }) as string);
      const res = await patchRemoveConsumable({ serialNumber: extra.serialNumber, index: extra.index });
      !(res as { error: FetchBaseQueryError | SerializedError }).error && setRemove(true);
    },
  });

  const { ConfirmDialog: ConfirmDepleteDialog, confirm: confirmDepleteDialog } = useConfirmDialog({
    title: t('empty') + ' ' + t('consumableSubsets'),
    message: (extra: any) => t('depleteMessage', { entity: t('consumableSubsets'), name: subsetData?.name }) as string,
    onConfirm: async (extra: any) => {
      setLastAction(ACTIONS.Deplete);
      setActionStatus(ACTION_STATUS.Loading);
      setPerformedAction(t('depletingConsumableXXX', { number: consumable.index, name: subsetData?.name }) as string);
      const res = await patchDepleteConsumable({ serialNumber: deviceId!, body: { index: consumable.index } });
      !(res as { error: FetchBaseQueryError | SerializedError }).error && setPercentageFill(0);
    },
  });

  const handleClickEdit = () => {
    deviceStatus === true && openDialog(consumable.consumable.id);
  };

  const handleClickRemove = () => {
    confirm({ serialNumber: deviceId!, index: consumable.index });
  };

  const handleClickSetMax = async (subsetId: string) => {
    openReplenishDialog(subsetId);
  };

  const handleClickSetMin = () => {
    confirmDepleteDialog({ serialNumber: deviceId!, index: consumable.index });
  };

  useEffect(() => {
    if (actionStatus === ACTION_STATUS.Error) {
      setDismissErrorAnimation(false);
    }
  }, [actionStatus]);

  const [dismissErrorAnimation, setDismissErrorAnimation] = useState(false);

  useEffect(() => {
    if (!subsetData?.id) {
      setRemove(true);
    }

    if (subsetData?.id) {
      setRemove(false);
    }
  }, [subsetData?.id]);

  return (
    <>
      <>
        {!empty && subsetData?.id ? (
          <Paper
            sx={(theme) => ({
              position: 'relative',
              backgroundColor: theme.palette.background.grayShades[0],
              display: 'grid',
              maxWidth: '600px',
              width: '100%',
              borderRadius: 1,
              zIndex: 1,
              outline: `0px solid ${alpha(theme.palette.error.light, 0.2)}`,
              ...(actionStatus === ACTION_STATUS.Error && !dismissErrorAnimation
                ? {
                    animation: `${boxShadowAnimation(theme)} 3000ms infinite alternate ${
                      theme.transitions.easing.easeInOut
                    }, ${shakeAnimation(theme)} 1s infinite`,
                  }
                : {}),
            })}
            elevation={1}
            onMouseOver={() =>
              actionStatus === ACTION_STATUS.Error && !dismissErrorAnimation && setDismissErrorAnimation(true)
            }
          >
            <LoadingFeedback loading={actionStatus === ACTION_STATUS.Loading} />
            <ErrorFeedback hasErrors={actionStatus === ACTION_STATUS.Error} action={performedAction} />
            <SuccessFeedback success={actionStatus === ACTION_STATUS.Success} />
            <Paper
              ref={ref}
              elevation={0}
              variant="outlined"
              sx={(theme) => ({
                backgroundColor: theme.palette.background.default,
                height: '100%',
                minHeight: '370px',
                display: 'flex',
                justifyContent: 'stretch',
                alignItems: 'center',
                flexDirection: 'column',
                padding: 2,
                borderRadius: 1,
                width: '100%',
              })}
            >
              <Grid
                container={true}
                direction={'row'}
                sx={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: 1, height: '100%' }}
              >
                <AreaRow sx={{ display: 'grid', gridTemplateRows: '1.5fr 1fr', gap: 1 }}>
                  <AreaContainer>
                    <AreaHeader>
                      <Subtitle>{t('subset')}</Subtitle>
                      {subsetData?.id && (
                        <Chip
                          icon={<TagIcon />}
                          sx={(theme) => ({
                            backgroundColor: theme.palette.background.grayShades[0],
                          })}
                          label={subsetData.id}
                          size="small"
                          color="default"
                          variant="outlined"
                        />
                      )}
                    </AreaHeader>
                    <AreaBody>
                      {subsetData?.name && <Title>{subsetData.name}</Title>}
                      {subsetData?.description && (
                        <Typography variant="body2" color="text.primary">
                          {subsetData.description}
                        </Typography>
                      )}

                      {consumable?.batch && (
                        <>
                          <Typography variant="body2" color="text.secondary" sx={{ marginTop: 2 }}>
                            {t('batch')}
                          </Typography>
                          <Typography variant="body2" color="text.primary">
                            {consumable.batch}
                          </Typography>
                        </>
                      )}
                    </AreaBody>
                  </AreaContainer>
                  <CardSlot
                    sx={(theme) => ({
                      position: 'relative',
                      overflow: 'hidden',
                      '&:before': {
                        content: differenceInDays >= 0 ? '""' : 'none',
                        backgroundColor: theme.palette.background.grayShades[1],
                        width: '16px',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                      },
                      '&:after': {
                        content: isExpired ? '""' : 'none',
                        backgroundColor: theme.palette.background.grayShades[1],
                        width: '16px',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                      },
                      '& > *:not([data-target="consumable-progress"])': {
                        zIndex: 1,
                      },
                    })}
                  >
                    <Box
                      data-target="consumable-progress"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: '16px',
                        height: '100%',
                        width: 'calc(100% - 32px)',
                      }}
                    >
                      <Box
                        sx={(theme) => ({
                          position: 'relative',
                          width: safeExpirationPercentage + '%',
                          height: '100%',
                          backgroundColor: theme.palette.background.grayShades[1],
                          transition: 'all 300ms ease-in-out',
                        })}
                      ></Box>
                    </Box>
                    <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1 }}>
                      {!isExpired ? t('expiresAt') : t('expiredAt')}
                    </Typography>
                    <Typography variant="h5" color="text.primary" fontWeight={800}>
                      {expirationDate}
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      {!isExpired ? `${t('willExpire')} ${dayjs().to(end)}` : `${t('expired')} ${end.from(dayjs())}`}
                    </Typography>
                    {(subsetData?.lifespan || consumable.installedAt) && (
                      <Box
                        sx={(theme) => ({
                          background: `linear-gradient(90deg, ${theme.palette.success.light} 0%, ${yellow[500]} 51.5%,
                              ${theme.palette.warning.light} 75.5%, ${theme.palette.error.light} 100%)`,
                          height: '10px',
                          borderRadius: 3,
                          marginTop: 1,
                          position: 'relative',
                          '&:after': {
                            content: '""',
                            backgroundColor: theme.palette.background.default,
                            width: '21px',
                            height: '21px',
                            position: 'absolute',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                            left: `clamp(0%,${calcDot},100%)`,
                            borderRadius: '100%',
                            border: `1px solid hsl(${100 - safeExpirationPercentage} 61% 41% / 1)`,
                            transition: 'all 300ms ease-in-out',
                          },
                        })}
                      ></Box>
                    )}
                    {subsetData.lifespan || lifespan ? (
                      <Chip
                        icon={<LoopOutlinedIcon style={{ fontSize: '12px' }} />}
                        sx={(theme) => ({
                          backgroundColor: theme.palette.background.grayShades[1],
                          position: 'absolute',
                          right: '16px',
                        })}
                        label={`${t('lifespan')} ${subsetData.lifespan || lifespan}  ${t('days')}`}
                        size="small"
                        color="default"
                        variant="outlined"
                      />
                    ) : (
                      <></>
                    )}
                  </CardSlot>
                </AreaRow>
                <CardSlot sx={{ width: '100%', padding: 0 }}>
                  <Area>
                    <Box>
                      <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                        {t('fillLevel')}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        marginY: 1,
                        marginX: 'auto',
                      }}
                    >
                      <CircularProgress
                        variant="determinate"
                        size={'100%'}
                        value={100}
                        thickness={5}
                        sx={{ position: 'absolute', color: `hsl(${percentageFill} 100% 65% / 0.25)` }}
                      />
                      <CircularProgress
                        variant="determinate"
                        size={'100%'}
                        thickness={5}
                        value={percentageFill}
                        sx={{
                          color: `hsl(${percentageFill} 61% 41% / 1)`,
                          strokeLinecap: 'round',
                        }}
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography
                          variant="body1"
                          component="div"
                          color={`hsl(${percentageFill} 61% 41% / 1)`}
                          fontWeight={800}
                          fontSize={35}
                          lineHeight={1.2}
                          display="flex"
                          alignItems="center"
                        >
                          {percentageFill}
                          <span style={{ fontSize: '20px' }}>%</span>
                        </Typography>
                        <Typography
                          variant="body2"
                          component="div"
                          color="#bbb"
                          fontWeight={800}
                          fontSize={12}
                          lineHeight={1}
                        >
                          {numberValueCircularBar(percentageFill, consumable)} of {subsetData.rangeFullCapacity}
                        </Typography>
                      </Box>
                    </Box>
                  </Area>
                  <Area>
                    <Box>
                      <Button
                        onClick={() => handleClickSetMax(subsetData.id)}
                        startIcon={<InvertColorsOutlinedIcon />}
                        variant="outlined"
                        disabled={!deviceStatus || percentageFill === 100}
                        sx={{
                          width: '100%',
                          fontSize: '14px',
                          borderRadius: 1,
                          border: '2px solid rgb(39 93 163)',
                          marginBottom: 1,
                          paddingX: 1,
                          paddingY: 0.5,
                          '&:hover': { border: '2px solid' },
                        }}
                      >
                        {t('replenish')}
                      </Button>
                      <Button
                        onClick={() => handleClickSetMin()}
                        startIcon={<InvertColorsOffOutlinedIcon />}
                        variant="outlined"
                        disabled={!deviceStatus || percentageFill === 0}
                        sx={{
                          width: '100%',
                          fontSize: '14px',
                          borderRadius: 1,
                          border: '2px solid rgb(39 93 163)',
                          paddingX: 1,
                          paddingY: 0.5,
                          '&:hover': { border: '2px solid' },
                        }}
                      >
                        {t('disable')}
                      </Button>
                    </Box>
                  </Area>
                </CardSlot>
              </Grid>
            </Paper>
            <AreaRow ref={refGrey} sx={{ alignItems: 'end', justifyContent: 'space-between' }}>
              <Area>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'left' }}>
                  <Box
                    sx={(theme) => ({
                      paddingX: 1,
                      paddingY: 0.5,
                      backgroundColor: theme.palette.background.grayShades[1],
                      borderRadius: 1,
                      textAlign: 'center',
                      marginRight: 1,
                    })}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ textTransform: 'uppercase', fontSize: '6px' }}
                    >
                      {t('slot')}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      fontWeight={800}
                      sx={{ lineHeight: 1, fontSize: '12px' }}
                    >
                      {slotCount < 10 ? '0' + slotCount : slotCount}
                    </Typography>
                  </Box>
                  <Typography
                    variant="h5"
                    color="text.primary"
                    fontWeight={800}
                    sx={{ lineHeight: 1, fontSize: '22px' }}
                  >
                    {consumableData.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    gap: 1,
                    marginTop: 1,
                  }}
                >
                  <Chip
                    icon={iconPerKind(consumableData.kind)}
                    sx={(theme) => ({
                      backgroundColor: theme.palette.background.grayShades[1],
                    })}
                    label={consumableData.kind}
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                  <Chip
                    icon={<TagIcon />}
                    sx={(theme) => ({
                      backgroundColor: theme.palette.background.grayShades[1],
                    })}
                    label={consumableData.id}
                    size="small"
                    color="default"
                    variant="outlined"
                  />
                </Box>
              </Area>
              <Area sx={{ alignItems: 'end', justifyContent: 'end', flexDirection: 'row' }}>
                <Button
                  onClick={() => handleClickEdit()}
                  startIcon={<RepeatIcon sx={{ mr: '0.2rem' }} />}
                  disabled={!deviceStatus}
                  variant="outlined"
                  sx={{
                    fontSize: '14px',
                    borderRadius: 1,
                    border: '2px solid rgb(39 93 163)',
                    paddingX: 1,
                    paddingY: 0.5,
                    '&:hover': { border: '2px solid' },
                  }}
                >
                  {t('change')}
                </Button>
                <Button
                  onClick={() => handleClickRemove()}
                  startIcon={<CropFreeIcon sx={{ mr: '0.2rem' }} />}
                  disabled={!deviceStatus}
                  color="error"
                  variant="outlined"
                  sx={{
                    fontSize: '14px',
                    borderRadius: 1,
                    border: '2px solid',
                    paddingX: 1,
                    paddingY: 0.5,
                    '&:hover': { border: '2px solid' },
                    '&:focus': { outline: '4px auto red' },
                  }}
                >
                  {t('remove')}
                </Button>
              </Area>
            </AreaRow>
          </Paper>
        ) : (
          <Paper
            onClick={() => handleClickEdit()}
            sx={(theme) => ({
              backgroundColor: theme.palette.background.grayShades[0],
              display: 'grid',
              cursor: deviceStatus === true ? 'pointer' : 'default',
              borderRadius: 1,
              boxShadow: 1,
              position: 'relative',
              zIndex: 1,
              maxWidth: '600px',
              width: '100%',
            })}
          >
            <LoadingFeedback loading={actionStatus === ACTION_STATUS.Loading} />
            <ErrorFeedback hasErrors={actionStatus === ACTION_STATUS.Error} action={performedAction} />
            <SuccessFeedback success={actionStatus === ACTION_STATUS.Success} />
            <Paper
              elevation={0}
              variant="outlined"
              sx={(theme) => ({
                backgroundColor: theme.palette.background.default,
                height: heightWhiteCard,
                minHeight: '370px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                borderRadius: 1,
                width: '100%',
              })}
            >
              <FilterCenterFocusOutlinedIcon sx={{ fontSize: '80px', color: '#bbb' }} />
              <Typography variant="h6" color="#a2a1a1" fontWeight={800} sx={{ textTransform: 'uppercase' }}>
                {t('emptySlot')}
              </Typography>
            </Paper>
            <AreaRow
              sx={{
                height: '86px',
                alignItems: 'end',
                justifyContent: 'space-between',
              }}
            >
              <Area sx={{ gap: 0 }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'left' }}>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: theme.palette.background.grayShades[1],
                      padding: '2px 6px',
                      borderRadius: 1,
                      textAlign: 'center',
                      marginRight: 1,
                    })}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ textTransform: 'uppercase', fontSize: '6px' }}
                    >
                      {t('slot')}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      fontWeight={800}
                      sx={{ lineHeight: 1, fontSize: '12px' }}
                    >
                      {slotCount < 10 ? '0' + slotCount : slotCount}
                    </Typography>
                  </Box>
                  <Typography
                    variant="h5"
                    color="text.primary"
                    fontWeight={800}
                    sx={{ lineHeight: 1, fontSize: '22px' }}
                  >
                    {consumableData.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    gap: 1,
                    marginTop: 1,
                  }}
                >
                  <Chip
                    icon={iconPerKind(consumableData.kind)}
                    sx={(theme) => ({
                      cursor: deviceStatus === true ? 'pointer' : 'default',
                      backgroundColor: theme.palette.background.grayShades[1],
                    })}
                    label={consumableData.kind}
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                  <Chip
                    icon={<TagIcon />}
                    sx={(theme) => ({
                      cursor: deviceStatus === true ? 'pointer' : 'default',
                      backgroundColor: theme.palette.background.grayShades[1],
                    })}
                    label={consumableData.id}
                    size="small"
                    color="default"
                    variant="outlined"
                  />
                </Box>
              </Area>
              <Area sx={{ alignItems: 'end', justifyContent: 'end', flexDirection: 'row' }}>
                <Button
                  startIcon={<AddBoxOutlinedIcon sx={{ mr: '0.2rem' }} />}
                  disabled={!deviceStatus}
                  variant="outlined"
                  sx={{
                    fontSize: '14px',
                    borderRadius: 1,
                    border: '2px solid',
                    paddingX: 1,
                    paddingY: 0.5,
                    '&:hover': { border: '2px solid' },
                  }}
                >
                  {t('add')}
                </Button>
              </Area>
            </AreaRow>
          </Paper>
        )}
      </>
      <ReplenishDialog />
      <AddEditDialog />
      <ConfirmDialog />
      <ConfirmDepleteDialog />
    </>
  );
}
