import useAlarmColumns from './useAlarmsColumns';
import { AlarmReq, AlarmRes } from '@/components/OneTools/Channels/Alarms/typings';
import {
  useDeleteAlarmMutation,
  useGetAlarmsQuery,
  useLazyGetAlarmQuery,
  usePutAlarmMutation,
  usePostAlarmMutation,
  useIsAlarmUniqueMutation,
} from '@/redux/api/system/alarmsApiSlice';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { useTranslation } from 'react-i18next';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { ApiResult, CustomErrors } from '@/shared/typings';
import { isValidJSON } from '@/shared/utils';
import { CHANNELS, ROLES, SENDING_STYLE, SEVERITY } from '@/shared/constants';
import { CommonIsEntityUniqueRes } from '../../typings';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';

export const AlarmsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<AlarmRes>));

export default function AlarmPanel() {
  const { t } = useTranslation();

  // Queries and mutations
  const { data, isLoading, isError } = useGetAlarmsQuery();
  const [getAlarm] = useLazyGetAlarmQuery();
  const [postAlarmTrigger] = usePostAlarmMutation();
  const [putAlarmTrigger] = usePutAlarmMutation();
  const [deleteAlarmTrigger] = useDeleteAlarmMutation();
  const [isAlarmUnique] = useIsAlarmUniqueMutation();

  const { AddEditDialog, openDialog } = useAddEditDialog<AlarmReq>({
    title: t(CHANNELS.ALARMS),
    baseConfig: [
      {
        name: 'name',
        placeholder: t('name'),
        options: { required: t('fieldRequiredError') as string },
        needsAsyncValidation: true,
      },
      {
        name: 'id',
        placeholder: t('key'),
        options: { required: t('fieldRequiredError') as string },
        needsAsyncValidation: true,
      },
      {
        name: 'description',
        placeholder: t('description'),
      },
      {
        type: 'autocomplete',
        name: 'sendingStyle',
        placeholder: t('sendingStyle'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(SENDING_STYLE).map((sendingStyle) => ({
            label: t(sendingStyle),
            value: sendingStyle,
          })),
        },
      },
      {
        type: 'autocomplete',
        name: 'role',
        placeholder: t('roles'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(ROLES).map((role) => ({ label: t(role), value: role })),
          multiple: true,
        },
      },
      {
        type: 'autocomplete',
        name: 'severity',
        placeholder: t('severity'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(SEVERITY).map((severity) => ({ label: t(severity), value: severity })),
        },
      },
      {
        type: 'checkbox',
        name: 'parameters',
        placeholder: t('parameters'),
      },
      {
        name: 'payloadExample',
        placeholder: t('payloadExample'),
        options: {
          required: false,
          deps: ['parameters'],
          validate: (value, formValues) => {
            if (formValues.parameters) {
              return value && isValidJSON(value) ? true : (t('JSONInvalid') as string);
            }
            return true;
          },
        },
        helperText: t('JSONHelperText') as string,
      },
    ],
    getEditData: async (id: string | boolean) => {
      const res = await getAlarm(id as string);
      const alarm: any = { ...res.data?.data };
      return alarm;
    },
    formValidation: async (formState, setCustomError, clearErrors, _, dialogId) => {
      const parameters = formState.parameters;
      if (!parameters) {
        setCustomError('payloadExample', CustomErrors.HIDE_FIELD);
      } else {
        clearErrors(['payloadExample']);
      }

      const isEditing = typeof dialogId === 'string';
      const isAlarmUniqueBody = {
        ...((formState.id && { id: formState.id || dialogId }) || {}),
        ...((formState.name && { name: formState.name }) || {}),
      };

      let isNameUnique: boolean | undefined = false;
      let isIdUnique: boolean | undefined = false;

      if (
        Object.keys(isAlarmUniqueBody).length === 0 ||
        (isEditing && data?.data.items.find((t) => t.id === dialogId && t.name === isAlarmUniqueBody.name))
      ) {
        if (isAlarmUniqueBody.id) {
          clearErrors(['id']);
          clearErrors(['root.id']);
        }

        if (isAlarmUniqueBody.name) {
          clearErrors(['name']);
          clearErrors(['root.name']);
        }
        return;
      }

      const isAlarmUniqueRes = await isAlarmUnique({ body: isAlarmUniqueBody, isEditing });

      if (
        (
          isAlarmUniqueRes as {
            error: FetchBaseQueryError | SerializedError;
          }
        ).error
      ) {
        // TODO - Handle the possible error
        return;
      }

      isNameUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isAlarmUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.name
      );
      isIdUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isAlarmUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.id
      );
      isNameUnique = (isNameUnique === undefined && true) || isNameUnique;
      isIdUnique = (isIdUnique === undefined && true) || isIdUnique;

      if (!isEditing && !isIdUnique) {
        setCustomError('id', CustomErrors.UNIQUE);
        setCustomError('root.id', CustomErrors.UNIQUE);
      }

      if (!isEditing && isIdUnique) {
        clearErrors(['id']);
        clearErrors(['root.id']);
      }

      if (!isNameUnique) {
        setCustomError('name', CustomErrors.UNIQUE);
        setCustomError('root.name', CustomErrors.UNIQUE);
      }

      if (isNameUnique) {
        clearErrors(['name']);
        clearErrors(['root.name']);
      }
    },
    onSubmit: async (id: string | boolean, data: any) => {
      const isEditing = typeof id === 'string';
      if (!data.parameters) {
        data.parameters = false;
      }
      if (!data.parameters && data.payloadExample) {
        delete data.payloadExample;
      }

      if (isEditing) {
        await putAlarmTrigger({ id, body: data });
      } else {
        await postAlarmTrigger(data);
      }
    },
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t(CHANNELS.ALARMS),
    message: (extra: any) =>
      t('deleteMessage', {
        entity: t(CHANNELS.ALARMS),
        name: extra.name,
        interpolation: { escapeValue: false },
      }) as string,
    onConfirm: (extra: any) => deleteAlarmTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useAlarmColumns(openDialog, confirm);

  return (
    <>
      <AlarmsTable
        title={t(CHANNELS.ALARMS)}
        data={data?.data.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('alarm')}
        onResetStateButtonClick={openDialog}
        exportData={true}
        selection={true}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
