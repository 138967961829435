import { Schema as S } from '@effect/schema';

export type EntityWithParameters = {
  parameters: true;
  payloadExample: string;
};

export type EntityWithoutParameters = {
  parameters: false;
  payloadExample: undefined;
};

export interface ResCommonFields {
  id: string;
  createdAt: string;
  updatedAt: string;
}

export type CommonIsEntityUniqueBase = {
  id?: string;
  name?: string;
};

export type CommonIsEntityUniqueReq = {
  body: CommonIsEntityUniqueBase;
  isEditing?: boolean;
};

export type CommonIsEntityUniqueRes = Record<keyof CommonIsEntityUniqueBase, boolean | undefined>;

export type CommonIsEntityUniqueRes_Type = S.Schema.Type<typeof CommonIsEntityUniqueRes_Struct>;
export const CommonIsEntityUniqueRes_Struct = S.Struct({
  id: S.Boolean,
  name: S.Boolean,
});
export type PartialCommonIsEntityUniqueRes_Type = S.Schema.Type<typeof PartialCommonIsEntityUniqueRes_Struct>;
export const PartialCommonIsEntityUniqueRes_Struct = CommonIsEntityUniqueRes_Struct.pipe(
  S.pick('id', 'name'),
  S.partial()
).annotations({
  identifier: 'PartialCommonIsEntityUniqueRes_Struct',
});

export const decodeCommonIsEntityUniqueResponse = S.decodeUnknownEither(PartialCommonIsEntityUniqueRes_Struct, {
  errors: 'all',
});

export function eitherCommonIsEntityUnique(response: unknown) {
  const isUnique = decodeCommonIsEntityUniqueResponse(response);
  if (isUnique && isUnique._tag === 'Left') {
    return {
      _tag: isUnique._tag,
      left: isUnique.left,
    };
  }
  return {
    _tag: isUnique._tag,
    right: isUnique.right,
  };
}
