import useAddEditDialog from '@/hooks/useAddEditDialog';
import {
  useGetLoyaltyProgramsQuery,
  useIsLoyaltyProgramUniqueMutation,
  usePostLoyaltyProgramMutation,
  usePutLoyaltyProgramMutation,
  useDeleteLoyaltyProgramMutation,
  useLazyGetLoyaltyProgramQuery,
} from '@/redux/api/system/loyaltyProgramsApiSlice';
import { useTranslation } from 'react-i18next';
import { LoyaltyProgramRes, LoyaltyProgramReq } from './typings';
import useLoyaltyProgramsColumns from './useLoyaltyProgramsColumns';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { CHANNELS, LOYALTY_PROGRAMS_CONFIGURATION_TYPE } from '@/shared/constants';
import { CustomErrors } from '@typings';
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { decodeCommonIsEntityUniqueResponse } from '../../typings';

export const LoyaltyProgramsTable = withErrorLoadingManagement(
  withResetNavigationState(GenericExportTable<LoyaltyProgramRes>)
);

export default function LoyaltyProgramsPanel() {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetLoyaltyProgramsQuery();

  const [triggerGetLoyaltyProgram] = useLazyGetLoyaltyProgramQuery();
  const [postLoyaltyProgramTrigger] = usePostLoyaltyProgramMutation();
  const [putLoyaltyProgramTrigger] = usePutLoyaltyProgramMutation();
  const [deleteLoyaltyProgramTrigger] = useDeleteLoyaltyProgramMutation();
  const [isLoyaltyProgramUnique] = useIsLoyaltyProgramUniqueMutation();

  const { AddEditDialog, openDialog } = useAddEditDialog<
    Omit<LoyaltyProgramReq, 'config'> & LoyaltyProgramReq['config']
  >({
    title: t(CHANNELS.LOYALTY_PROGRAMS),
    baseConfig: [
      {
        name: 'name',
        placeholder: t('name'),
        options: { required: t('fieldRequiredError') as string },
        needsAsyncValidation: true,
      },
      {
        name: 'id',
        placeholder: t('key'),
        options: { required: t('fieldRequiredError') as string },
        needsAsyncValidation: true,
      },
      {
        name: 'enabled',
        placeholder: t('enabled'),
        type: 'checkbox',
      },
      {
        type: 'autocomplete',
        group: 'configuration',
        name: 'type',
        placeholder: t('type'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(LOYALTY_PROGRAMS_CONFIGURATION_TYPE).map((loyaltyProgram) => ({
            label: t(loyaltyProgram),
            value: loyaltyProgram,
          })),
        },
      },
      {
        type: 'number',
        group: 'configuration',
        helperText: t('fieldNumberError') as string,
        name: 'value',
        placeholder: t('value'),
        options: { required: t('fieldRequiredError') as string },
      },
    ],
    getEditData: async (dialogId: string | boolean) => {
      if (typeof dialogId === 'boolean') {
        return;
      }
      const { data } = await triggerGetLoyaltyProgram(dialogId);
      if (!data || data._tag === 'Left' || data.right.success === false) {
        console.error('Error while trying to edit data', (data as any)?.right?.error || (data as any)?.left || data);
        return;
      }
      const item = data.right.data;
      if (!item) {
        return;
      }
      const { config, ...rest } = item;
      return {
        ...rest,
        type: config.type,
        value: config.value,
      };
    },
    formValidation: async (formState, setCustomError, clearErrors, _, dialogId) => {
      const isEditing = typeof dialogId === 'string';
      const isLoyaltyProgramsUniqueBody = {
        ...((formState.id && { id: formState.id || dialogId }) || {}),
        ...((formState.name && { name: formState.name }) || {}),
      };

      let isNameUnique: boolean | undefined = false;
      let isIdUnique: boolean | undefined = false;
      if (!data || data._tag === 'Left' || data.right.success === false) {
        console.error('Error while trying to edit data', (data as any)?.right?.error || (data as any)?.left || data);
        return;
      }
      if (
        Object.keys(isLoyaltyProgramsUniqueBody).length === 0 ||
        (isEditing &&
          data.right.data.items.find((t) => t.id === dialogId && t.name === isLoyaltyProgramsUniqueBody.name))
      ) {
        if (isLoyaltyProgramsUniqueBody.id) {
          clearErrors(['id']);
          clearErrors(['root.id']);
        }

        if (isLoyaltyProgramsUniqueBody.name) {
          clearErrors(['name']);
          clearErrors(['root.name']);
        }
        return;
      }

      const isLoyaltyProgramsUniqueRes = await isLoyaltyProgramUnique({ body: isLoyaltyProgramsUniqueBody, isEditing });
      if (
        (
          isLoyaltyProgramsUniqueRes as {
            error: FetchBaseQueryError | SerializedError;
          }
        ).error
      ) {
        // TODO - Handle the possible error
        return;
      }

      const _isLoyaltyProgramsUniqueRes = isLoyaltyProgramsUniqueRes as {
        data: ReturnType<typeof decodeCommonIsEntityUniqueResponse>;
      };
      if (_isLoyaltyProgramsUniqueRes.data._tag === 'Left') {
        return;
      }

      isNameUnique = [true, false].find((bool) => {
        if (_isLoyaltyProgramsUniqueRes.data._tag === 'Left') {
          return false;
        }
        return bool === _isLoyaltyProgramsUniqueRes.data.right.name;
      });

      isIdUnique = [true, false].find((bool) => {
        if (_isLoyaltyProgramsUniqueRes.data._tag === 'Left') {
          return false;
        }
        return bool === _isLoyaltyProgramsUniqueRes.data.right.id;
      });

      isNameUnique = (isNameUnique === undefined && true) || isNameUnique;
      isIdUnique = (isIdUnique === undefined && true) || isIdUnique;

      if (!isEditing && !isIdUnique) {
        setCustomError('id', CustomErrors.UNIQUE);
        setCustomError('root.id', CustomErrors.UNIQUE);
      }

      if (!isEditing && isIdUnique) {
        clearErrors(['id']);
        clearErrors(['root.id']);
      }

      if (!isNameUnique) {
        setCustomError('name', CustomErrors.UNIQUE);
        setCustomError('root.name', CustomErrors.UNIQUE);
      }

      if (isNameUnique) {
        clearErrors(['name']);
        clearErrors(['root.name']);
      }
    },

    onSubmit: async (dialogId: string | boolean, data) => {
      const isEditing = typeof dialogId === 'string';
      const { type, value, ...rest } = data;
      const config = {
        type,
        value,
      };
      rest.enabled = rest.enabled || false;
      const submitData = { ...rest, config };
      if (isEditing) {
        await putLoyaltyProgramTrigger({ id: dialogId, body: submitData });
      } else {
        await postLoyaltyProgramTrigger(submitData);
      }
    },
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t('loyaltyProgram'),
    message: (extra: any) => t('deleteMessage', { entity: t('loyaltyProgram'), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteLoyaltyProgramTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useLoyaltyProgramsColumns(openDialog, confirm);
  if (data?._tag === 'Left' || !data || data.right.success === false) {
    return <></>;
  }
  return (
    <>
      <LoyaltyProgramsTable
        title={t(CHANNELS.LOYALTY_PROGRAMS)}
        data={data?.right.data.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('loyaltyProgram')}
        onResetStateButtonClick={openDialog}
        exportData={true}
        selection={true}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
