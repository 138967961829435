import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from '@mui/material';
import {
  AddEditDialogConfig,
  AddEditDialogConfigs,
  AddEditDialogProps,
  AutocompleteOption,
  MutationEvent,
  Mutations,
  MutationsField,
} from './typings';
import { CustomErrors } from '@/shared/typings';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import DialogInput from './DialogInput';
import { useTranslation } from 'react-i18next';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import { DevTool } from '@hookform/devtools';
import APP_CONFIG from '@/appConfig';
import RenderIf from '../RenderIf/RenderIf';
const getAutocompleteWrappedValue = (
  options: AutocompleteOption[],
  initialValue: string | string[],
  isMultiple?: boolean
) => {
  return !isMultiple
    ? options.find((o: AutocompleteOption) => o.value === initialValue)
    : initialValue.length > 0
    ? (initialValue as string[]).flatMap((v: string) => options.find((o: AutocompleteOption) => o.value === v) || [])
    : options.filter((o: AutocompleteOption) => initialValue.includes(o.value) || o.fixed);
};

const showFields = (config: AddEditDialogConfigs, ...fields: string[]) => {};
const hideFields = (config: AddEditDialogConfigs, ...fields: string[]) => {};

const getMutations = (mutations: MutationsField, config: AddEditDialogConfigs) => {
  return Object.entries(
    (typeof mutations === 'object'
      ? mutations
      : mutations &&
        mutations(
          (...fields: string[]) => showFields(config, ...fields),
          (...fields: string[]) => hideFields(config, ...fields)
        )) || {}
  ).reduce<{
    [K in keyof Mutations as `${K}Mutation`]?: MutationEvent;
  }>((acc, curr) => {
    return { ...acc, [`${curr[0]}Mutation`]: curr[1] };
  }, {});
};

export default function AddEditDialog({
  title,
  config: initialConfig,
  isOpen,
  isEditing,
  onClose,
  onSubmit,
  formValidation,
  asyncFields,
  groups,
  editId,
  mutations,
}: AddEditDialogProps) {
  const [config, setConfig] = useState<AddEditDialogConfigs>(initialConfig);
  const { t } = useTranslation();

  const useFormReturn = useForm();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
  } = useFormReturn;

  const _isEditing = isEditing;
  const needsInitialValues = config != null && config.some((item) => item.initialValue);
  const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const errorsNumber = Object.keys(errors).length;
  const isFormDisabled = useMemo(() => {
    let isDisabled = false;
    for (const key in errors) {
      if (errors[key]?.type !== 'custom') {
        isDisabled = true;
        break;
      }
    }
    return isDisabled;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, errorsNumber]);

  const setCustomErrors = useCallback(
    (field: string, content: CustomErrors) => {
      setError(field, { type: 'custom', message: content });
    },
    [setError]
  );

  useEffect(() => {
    return () => {
      debounceTimeoutRef?.current && clearTimeout(debounceTimeoutRef.current);
    };
  }, [debounceTimeoutRef]);

  useEffect(() => {
    if (formValidation) {
      formValidation({}, setCustomErrors, clearErrors, errors); // trigger custom validation upon creation
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (!mutations) {
      return;
    }

    const { onMountMutation } = getMutations(mutations, initialConfig);

    if (!onMountMutation) {
      return;
    }

    const updatedConfig = onMountMutation.impacts.form?.handler(useFormReturn, initialConfig, editId);

    if (updatedConfig) {
      setConfig(updatedConfig);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formValidation) {
      return watch((value) => {
        if (debounceTimeoutRef?.current) {
          clearTimeout(debounceTimeoutRef.current);
        }

        if (asyncFields?.some((field) => Object.keys(value).includes(field))) {
          debounceTimeoutRef.current = setTimeout(() => {
            formValidation(value, setCustomErrors, clearErrors, errors);
          }, 1000);

          return;
        }

        formValidation(value, setCustomErrors, clearErrors, errors);
      }).unsubscribe;
    }
  }, [
    formValidation,
    watch,
    setCustomErrors,
    clearErrors,
    asyncFields,
    dirtyFields,
    debounceTimeoutRef,
    config,
    errors,
  ]);

  useEffect(() => {
    if (needsInitialValues) {
      for (const item of config) {
        if (item.initialValue) {
          // Wrapping autocomplete data
          if (item.type === 'autocomplete' && item.selectConfig) {
            if (typeof item.selectConfig.options === 'function') {
              item.selectConfig.options().then((options) => {
                setValue(
                  item.name,
                  getAutocompleteWrappedValue(
                    options,
                    item.initialValue as string | string[],
                    item.selectConfig!.multiple
                  )
                );
              });
            } else {
              setValue(
                item.name,
                getAutocompleteWrappedValue(
                  item.selectConfig.options,
                  item.initialValue as string | string[],
                  item.selectConfig.multiple
                )
              );
            }
            continue;
          }

          setValue(item.name, item.initialValue);
        }
      }
    }
  }, [config, needsInitialValues, setValue]);

  const onInvalidSubmit = () => {
    if (formValidation) {
      // N.B. only custom errors needs to be cleaned on invalid submission!
      const clearCustomErrors = (fields: string[]) => {
        for (const field of fields) {
          errors[field]?.type === 'custom' && clearErrors(field);
        }
      };

      const data = getValues();
      formValidation(data, setCustomErrors, clearCustomErrors, errors);
    }
  };

  const _onSubmit = (data: any) => {
    const submitData = { ...data };
    for (const key in submitData) {
      if (!submitData[key] && typeof submitData[key] !== 'number') {
        delete submitData[key];
      }
    }

    for (const key in config) {
      // Unwrapping autocomplete data
      const configItem = config[key as any];
      if (configItem.type === 'autocomplete') {
        let formField: AutocompleteOption | AutocompleteOption[] = submitData[configItem.name];
        if (Array.isArray(formField)) submitData[configItem.name] = formField.map((item) => item.value);
        else submitData[configItem.name] = formField?.value;
      }
    }

    _onClose();
    onSubmit(submitData);
  };

  const _onClose = () => {
    onClose();
    reset();
  };

  let currentGroup: string | null = null;
  const theme = useTheme();
  let groupBackground: { curr: string | null; prev: string | null } = {
    curr: null,
    prev: theme.palette.background.grayShades[0],
  };

  const renderTitle = (groupName: string) => {
    if (!groups || currentGroup === groupName) {
      return <></>;
    }

    if (!currentGroup || currentGroup !== groupName) {
      currentGroup = groupName;
      const currentColor = groupBackground.curr;
      groupBackground.curr = groupBackground.prev;
      groupBackground.prev = currentColor;
    }
    const group = Object.keys(groups).find((key) => key === groupName);

    if (!group) {
      return <></>;
    }

    return (
      <Box
        sx={{
          pt: 4,
          px: 2,
          ...(groupBackground.curr ? { backgroundColor: groupBackground.curr } : {}),
        }}
      >
        <Typography fontSize={20} fontWeight={700} sx={{ position: 'relative' }}>
          {groups[group].title}
        </Typography>
        {groups[group].description && <Typography fontSize={13}>{groups[group].description}</Typography>}
      </Box>
    );
  };

  const handleOnChangeFormMutation = async () => {
    if (!mutations) {
      return;
    }

    const formTriggeredMutations = getMutations(mutations, initialConfig);

    if (!formTriggeredMutations.onChangeMutation) {
      return;
    }

    if (formTriggeredMutations.onChangeMutation.impacts.fields) {
      await Promise.all(
        formTriggeredMutations.onChangeMutation.impacts.fields?.map(async (field) => {
          const targetFieldInitialConfig: AddEditDialogConfig | undefined = config?.find((c) => c.name === field.name);
          if (targetFieldInitialConfig) {
            field.handler(useFormReturn, initialConfig, editId);
          }
        })
      );
    }

    if (formTriggeredMutations.onChangeMutation.impacts.form) {
      const updatedConfig = formTriggeredMutations.onChangeMutation.impacts.form.handler(
        useFormReturn,
        initialConfig,
        editId
      );

      if (!updatedConfig) {
        return;
      }

      const values = getValues();
      setConfig(
        updatedConfig.map((config) => ({
          ...config,
          initialValue: config.type !== 'autocomplete' ? values[config.name] : values[config.name]?.value,
        }))
      );
    }
  };

  const _onChange = async ({ name }: { name: string }, eventHandler: Function) => {
    eventHandler();

    const fieldTriggeredMutations = config?.find((c) => {
      let _mutations = c.name === name && c.mutations;

      if (_mutations && typeof _mutations === 'object' && _mutations.onChange) {
        return true;
      }

      if (
        _mutations &&
        typeof _mutations !== 'object' &&
        _mutations(
          () => {},
          () => {}
        ).onChange
      ) {
        return true;
      }

      return false;
    })?.mutations;

    if (!fieldTriggeredMutations) {
      return;
    }

    const { onChangeMutation } = getMutations(fieldTriggeredMutations, config);

    if (onChangeMutation && onChangeMutation.impacts.fields) {
      await Promise.all(
        onChangeMutation.impacts.fields?.map(async (field) => {
          const targetFieldInitialConfig: AddEditDialogConfig | undefined = config?.find((c) => c.name === field.name);
          if (targetFieldInitialConfig) {
            field.handler(useFormReturn, initialConfig, editId);
          }
        })
      );
    }

    if (onChangeMutation && onChangeMutation.impacts.form) {
      onChangeMutation.impacts.form.handler(useFormReturn, initialConfig, editId);
    }

    handleOnChangeFormMutation();
  };

  const _register: typeof register = (name: any, options?: RegisterOptions<any, any>) => {
    const { onChange, ...registerProps } = register(name, options);
    let _onChange = (event: { target: any; type?: any }) => {
      handleOnChangeFormMutation();
      return onChange(event);
    };

    const mutations = config?.find((c) => {
      let _mutations = c.name === name && c.mutations;

      if (_mutations && typeof _mutations === 'object' && _mutations.onChange) {
        return true;
      }

      if (
        _mutations &&
        typeof _mutations !== 'object' &&
        _mutations(
          () => {},
          () => {}
        ).onChange
      ) {
        return true;
      }

      return false;
    })?.mutations;

    if (mutations) {
      const { onChangeMutation } = getMutations(mutations, config);

      if (onChangeMutation) {
        _onChange = async (event: { target: any; type?: any }) => {
          const onChangeRes = onChange(event);

          if (onChangeMutation && onChangeMutation.impacts.fields) {
            await Promise.all(
              onChangeMutation.impacts.fields?.map(async (field) => {
                const targetFieldInitialConfig: AddEditDialogConfig | undefined = config?.find(
                  (c) => c.name === field.name
                );
                if (targetFieldInitialConfig) {
                  field.handler(useFormReturn, config, editId);
                }
              })
            );
          }

          handleOnChangeFormMutation();

          return onChangeRes;
        };
      }
    }

    return {
      ...registerProps,
      onChange: _onChange,
    };
  };

  const form = config && (
    <form onSubmit={handleSubmit(_onSubmit, onInvalidSubmit)}>
      <DialogContent sx={{ p: 0, pb: 3 }}>
        {config.map(
          (item, i) =>
            errors[item.name]?.message?.toString() !== CustomErrors.HIDE_FIELD && (
              <React.Fragment key={item.name}>
                {item.group ? (
                  <Box
                    {...(groupBackground.curr
                      ? {
                          sx: {
                            backgroundColor: groupBackground.curr,
                          },
                        }
                      : {})}
                  >
                    {renderTitle(item.group)}
                    <Box
                      px={2}
                      py={1}
                      {...(groupBackground.curr
                        ? {
                            sx: {
                              backgroundColor: groupBackground.curr,
                            },
                          }
                        : {})}
                    >
                      <DialogInput
                        type={item.type}
                        configItem={item}
                        hasErrors={!!errors[item.name]}
                        register={_register}
                        control={control}
                        getValues={getValues}
                        onChange={_onChange}
                        {...((_isEditing && 'id' === item.name && { disabled: true }) || {})}
                      />
                      {!!errors[item.name] && (
                        <Typography color="error" fontSize={12} marginLeft={'14px'}>
                          {errors[item.name]?.message?.toString()}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box my={1} px={2} key={item.name}>
                    <DialogInput
                      type={item.type}
                      configItem={item}
                      hasErrors={!!errors[item.name]}
                      register={_register}
                      control={control}
                      getValues={getValues}
                      onChange={_onChange}
                      {...((_isEditing && 'id' === item.name && { disabled: true }) || {})}
                    />
                    {!!errors[item.name] && (
                      <Typography color="error" fontSize={12} marginLeft={'14px'}>
                        {errors[item.name]?.message?.toString()}
                      </Typography>
                    )}
                  </Box>
                )}
              </React.Fragment>
            )
        )}
      </DialogContent>
      <DialogActions sx={{ px: 2, pb: 2 }}>
        <Button variant="outlined" onClick={_onClose}>
          {t('cancel')}
        </Button>
        <Button variant="contained" type="submit" disabled={isFormDisabled || !isDirty}>
          {t('save')}
        </Button>
      </DialogActions>
      <RenderIf condition={APP_CONFIG.environment === 'local'}>
        <DevTool control={control} />
      </RenderIf>
    </form>
  );

  const { dialog } = useCulliganDialog({
    open: isOpen,
    handleClose: (_: any, reason: string) => {
      if (reason !== 'backdropClick') {
        _onClose();
      }
    },
    fullWidth: !!config,
    transitionDuration: { appear: 300 },
    tabs: [
      {
        id: 'addEditDialog',
        label: `${_isEditing ? t('edit') : t('create')} ${title}`,
        body: form,
      },
    ],
    styles: {
      bodyContainer: {
        p: 0,
      },
    },
  });

  return config && dialog;
}

export const DialogLoading = () => (
  <Dialog open={true} transitionDuration={{ exit: 300 }}>
    <CircularProgress sx={{ margin: 4 }} />
  </Dialog>
);
