import { ApiResult, Job } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { CommonIsEntityUniqueReq, CommonIsEntityUniqueRes } from '@/components/OneTools/typings';
import { OTAReq, OTARes } from '@/components/OneTools/Ecosystem/OTA/typings';
import { roleAwareQuery } from '../roleAwareQuery';
import { OtaJobsRes } from '@/components/Fleet/Devices/DevicePanel/OtaJobsPanel/typings';
import { dispatchPendingToOperationsSlice } from '../dispatchPendingToOperations';
import { ECOSYSTEM } from '@/shared/constants';

const URL_PREFIX = `${API_VERSION.v1}/admin/device/ota`;
const URL_PREFIX_BUILD = `${URL_PREFIX}/build`;
const URL_PREFIX_PLURAL = `${URL_PREFIX_BUILD}s`;
const URL_PREFIX_V2 = `${API_VERSION.v2}/admin/device/ota`;

export type OTAJob = {
  name: string;
  version: string;
  buildFile: string;
  targetSelectionMode: 'filter' | 'deviceGroup';
  enabled: boolean;
  targetFilterParams: {
    serialNumbers: string[];
  };
};

export const OTAApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['OTA', 'OTAJob'] }).injectEndpoints({
  endpoints: (builder) => ({
    getOTAList: builder.query<ApiResult<{ items: OTARes[] }>, void>({
      query: () => URL_PREFIX_PLURAL + '/search',
      providesTags: ['OTA'],
    }),
    getOTAListByDeviceId: builder.query<ApiResult<{ items: OTARes[] }>, string>({
      query: (id: string) => `${URL_PREFIX_V2}/list?id=${id}`,
    }),
    getOTA: builder.query<ApiResult<OTARes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX_BUILD,
        params: { id },
      }),
      providesTags: ['OTA'],
    }),
    getOTAJobsDevicesList: builder.query<ApiResult<{ items: OtaJobsRes[] }>, string>({
      queryFn: roleAwareQuery('getDeviceOTAJobList', (arg) => ({
        params: { serialNumber: arg },
      })),
      providesTags: ['OTA', 'OTAJob'],
    }),
    getBuildFileDownloadURL: builder.query<ApiResult<{ item: string }>, string>({
      query: (id: string) => ({
        url: URL_PREFIX_PLURAL + '/url',
        params: { buildFile: id },
      }),
    }),
    getOTAJobProgress: builder.query<ApiResult<{ items: Job[] }>, string>({
      query: (id) => ({
        url: URL_PREFIX + '/job/progress',
        params: { id },
      }),
    }),
    isOTAUnique: builder.mutation<ApiResult<CommonIsEntityUniqueRes>, CommonIsEntityUniqueReq>({
      query: ({ body, isEditing }) => ({
        url: URL_PREFIX_BUILD + '/unique',
        method: 'POST',
        body: { ...body },
        params: { isEditing },
      }),
    }),
    postOTAJob: builder.mutation<ApiResult<{ item: { id: string } }>, OTAJob>({
      query: (body: OTAJob) => {
        const { version, ...rest } = body;
        return {
          url: URL_PREFIX + '/job',
          method: 'POST',
          body: rest,
        };
      },
    }),
    postOTA: builder.mutation<ApiResult<OTARes>, OTAReq>({
      query: (body: OTAReq) => ({
        url: URL_PREFIX_BUILD + '/create',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['OTA'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX_BUILD + '/create',
          method: 'POST',
          subject: arg.id,
          entity: ECOSYSTEM.OTA,
        });
      },
    }),
    putOTA: builder.mutation<ApiResult<OTARes>, { id: string; body: OTAReq }>({
      query: ({ id, body }: { id: string; body: OTAReq }) => ({
        url: URL_PREFIX_BUILD + '/put',
        method: 'PUT',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['OTA'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX_BUILD + '/put',
          method: 'PUT',
          subject: arg.body.id,
          entity: ECOSYSTEM.OTA,
        });
      },
    }),
    patchOTA: builder.mutation<ApiResult<OTARes>, { id: string; body: Partial<OTAReq> }>({
      query: ({ id, body }) => ({
        url: URL_PREFIX_BUILD + '/patch',
        method: 'PATCH',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['OTA'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX_BUILD,
          method: 'PATCH',
          subject: arg.id,
          entity: ECOSYSTEM.OTA,
        });
      },
    }),
    deleteOTA: builder.mutation<ApiResult<null>, string>({
      query: (id: string) => ({
        url: URL_PREFIX_BUILD,
        method: 'DELETE',
        params: { id },
      }),
      invalidatesTags: ['OTA'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX_BUILD,
          method: 'DELETE',
          subject: arg,
          entity: ECOSYSTEM.OTA,
        });
      },
    }),
    uploadBuildFile: builder.mutation<{ success: true; error: null }, FormData>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const signedURLResponse = await fetchWithBQ({
          url: URL_PREFIX_PLURAL + '/upload-url',
          params: {
            model: _arg.get('model'),
            buildFile: _arg.get('id'),
            version: _arg.get('version'),
          },
        });
        if (signedURLResponse.error) return { error: signedURLResponse.error as FetchBaseQueryError };
        const {
          data: { item: signedURL },
        } = signedURLResponse.data as ApiResult<{ item: string }>;

        const result = await fetchWithBQ({
          url: signedURL,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream',
            'x-ms-blob-type': 'BlockBlob', // mandatory for file upload in Azure
          },
          body: _arg.get('buildFile'),
        });
        return result.meta?.response?.status === 201
          ? { data: { success: true, error: null } }
          : { error: result.error as FetchBaseQueryError };
      },
    }),
  }),
});

export const {
  useGetOTAListQuery,
  useGetOTAJobsDevicesListQuery,
  useLazyGetOTAJobsDevicesListQuery,
  useLazyGetOTAListQuery,
  useLazyGetOTAListByDeviceIdQuery,
  useGetOTAQuery,
  useLazyGetOTAQuery,
  useLazyGetBuildFileDownloadURLQuery,
  useLazyGetOTAJobProgressQuery,
  useIsOTAUniqueMutation,
  usePostOTAJobMutation,
  usePostOTAMutation,
  usePutOTAMutation,
  usePatchOTAMutation,
  useDeleteOTAMutation,
  useUploadBuildFileMutation,
} = OTAApiSlice;
