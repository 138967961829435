import { Column } from '@material-table/core';
import { Avatar, Box, Button, Chip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import ReleatedEntityLabel from '@/components/Shared/Chips/ReleatedEntityChip';
import { FleetError } from './typings';
import BlipChip from '@/components/Shared/Chips/BlipChip';
import { useGetDevicesQuery } from '@/redux/api/fleet/devicesApiSlice';
import { typeObject } from '@/shared/utils';

export default function useErrorColumns({
  onDetailClick,
  excludeColumns,
}: {
  onDetailClick: Function;
  excludeColumns?: string[];
}): Array<Column<FleetError>> {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { data: allDevices } = useGetDevicesQuery();

  const columns_array: Array<Column<FleetError>> = [];

  return columns_array.concat([
    {
      title: t('date'),
      field: 'time',
      exportTransformer: (error) => dayjs(error.time).format('L'),
      hidden: excludeColumns?.includes('time'),
      render: (error) => (
        <>
          <Typography>{dayjs(error.time).format('L')}</Typography>
          <Typography variant="caption">{dayjs(error.time).fromNow()}</Typography>
        </>
      ),
    },
    {
      title: t('name'),
      field: 'name',
      hidden: excludeColumns?.includes('name'),
      exportTransformer: (error) => error.name || error.errorName,
      render: (error) => (
        <>
          <Typography>{error.name || error.errorName}</Typography>
        </>
      ),
    },
    ...(excludeColumns?.includes('deviceName')
      ? []
      : [
          typeObject<Column<FleetError>>({
            title: t('device'),
            field: 'device',
            exportTransformer: (error) => error.deviceName,
            customFilterAndSearch: (filter, deviceErrors) => {
              const deviceError = allDevices?.data.items.find((device) => deviceErrors.deviceId === device.id);
              return deviceError?.name!.indexOf(filter) !== -1;
            },
            hidden: excludeColumns?.includes('deviceName'),
            render: (error) => (
              <ReleatedEntityLabel
                text={error.deviceName || (t('device') as string)}
                onEntityClick={() => navigate(`/fleet/devices/${error.deviceId}`)}
              />
            ),
          }),
        ]),
    typeObject<Column<FleetError>>({
      title: t('status'),
      customFilterAndSearch: (filter: any, error) => {
        const statusError = error.dismissed ? 'resolved' : 'active';
        return statusError.startsWith(filter);
      },
      exportTransformer: (error) => {
        const statusError = error.dismissed ? 'resolved' : 'active';
        return statusError;
      },
      field: 'dismissed',
      render: (error) => (
        <>
          {error.dismissed && (
            <Chip
              avatar={
                <Avatar sx={{ bgcolor: 'transparent' }}>
                  <Box
                    sx={{
                      height: '80%',
                      bgcolor: theme.palette.success.light,
                      width: '80%',
                      borderRadius: '50%',
                    }}
                  />
                </Avatar>
              }
              label={t('resolved')}
              size="small"
              color="success"
              variant="outlined"
            />
          )}
          {!error.dismissed && <BlipChip label={t('active')} size="small" color="error" variant="outlined" />}
          {error.dismissed && (
            <>
              <br />
              <Typography variant="caption">{dayjs(error.dismissedAt).fromNow()}</Typography>
            </>
          )}
        </>
      ),
    }),
    typeObject<Column<FleetError>>({
      title: t('actions'),
      hidden: excludeColumns?.includes('actions'),
      render: (error) => (
        <Box display="flex" gap={1}>
          <Button variant="outlined" color="primary" onClick={() => onDetailClick(error.id)}>
            {t('view')}
          </Button>
        </Box>
      ),
      sorting: false,
      searchable: false,
    }),
  ]);
}
