import useAddEditDialog from '@/hooks/useAddEditDialog';
import {
  useDeleteCommandMutation,
  useGetCommandsQuery,
  useIsCommandUniqueMutation,
  usePostCommandMutation,
  usePutCommandMutation,
} from '@/redux/api/system/commandsApiSlice';
import { useTranslation } from 'react-i18next';
import { ApiResult, CustomErrors } from '@typings';
import { CommandReq, CommandRes } from './typings';
import { ROLES, DIRECTIONS, COMMAND_TYPES, CHANNELS } from '@/shared/constants';
import { useCommandsColumns } from './useCommandsColumns';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { CommonIsEntityUniqueRes } from '../../typings';

const isValidJSON = (json: string) => {
  try {
    JSON.parse(json);
    return true;
  } catch {
    return false;
  }
};

export const CommandsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<CommandRes>));

export default function CommandsPanel() {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetCommandsQuery();
  const [postCommandTrigger] = usePostCommandMutation();
  const [putCommandTrigger] = usePutCommandMutation();
  const [deleteCommandTrigger] = useDeleteCommandMutation();
  const [isCommandUnique] = useIsCommandUniqueMutation();

  const getDialogOptions = () => {
    return {
      rolesOptions: Object.values(ROLES).map((role) => ({ label: t(role), value: role })),
      directionOptions: Object.values(DIRECTIONS).map((direction) => ({ label: t(direction), value: direction })),
      typeOptions: Object.values(COMMAND_TYPES).map((type) => ({ label: t(type), value: type })),
    };
  };

  const { rolesOptions, directionOptions, typeOptions } = getDialogOptions();

  const { AddEditDialog, openDialog } = useAddEditDialog<CommandReq>({
    title: t(CHANNELS.COMMANDS),
    baseConfig: [
      {
        name: 'name',
        placeholder: t('name'),
        options: { required: true },
        needsAsyncValidation: true,
      },
      {
        name: 'id',
        placeholder: t('key'),
        options: { required: true },
        needsAsyncValidation: true,
      },
      {
        name: 'description',
        placeholder: t('description'),
      },
      {
        type: 'autocomplete',
        name: 'direction',
        placeholder: t('direction'),
        options: { required: true },
        selectConfig: {
          options: directionOptions,
        },
      },
      {
        type: 'autocomplete',
        name: 'role',
        placeholder: t('roles'),
        options: { required: true },
        selectConfig: {
          options: rolesOptions,
          multiple: true,
        },
      },
      {
        type: 'autocomplete',
        name: 'type',
        placeholder: t('type'),
        options: { required: true },
        selectConfig: {
          options: typeOptions,
        },
      },
      {
        name: 'mandatory',
        type: 'checkbox',
        placeholder: t('mandatory'),
      },
      {
        name: 'parameters',
        type: 'checkbox',
        placeholder: t('parameters'),
      },
      {
        name: 'payloadExample',
        placeholder: t('payloadExample'),
        options: {
          required: false,
          deps: ['type'],
          validate: (value, formValues) => {
            if (!value && formValues?.type.value === COMMAND_TYPES.NO_PARAMS) {
              return true;
            }

            if (
              value &&
              [COMMAND_TYPES.PARAMS_WITHOUT_CONFIG, COMMAND_TYPES.PARAMS_WITH_CONFIG].includes(formValues?.type.value)
            ) {
              return value && isValidJSON(value) ? true : (t('JSONInvalid') as string);
            }
          },
        },
        helperText: t('JSONHelperText') as string,
      },
      {
        name: 'jsonSchema',
        placeholder: t('JSONSchema'),
        options: {
          required: false,
          deps: ['parameters'],
          validate: (value, formValues) => {
            if (
              !value &&
              [COMMAND_TYPES.NO_PARAMS, COMMAND_TYPES.PARAMS_WITHOUT_CONFIG].includes(formValues?.type.value)
            ) {
              return true;
            }

            if (value && formValues?.type.value === COMMAND_TYPES.PARAMS_WITH_CONFIG) {
              return value && isValidJSON(value) ? true : (t('JSONInvalid') as string);
            }
          },
        },
        helperText: t('JSONHelperText') as string,
      },
    ],
    getEditData: async (dialogId: string | boolean) => data?.data.items?.find((command) => command.id === dialogId),
    onSubmit: async (dialogId: string | boolean, data) => {
      const isEditing = typeof dialogId === 'string';

      if (data.type === COMMAND_TYPES.NO_PARAMS) {
        delete data.payloadExample;
        delete data.jsonSchema;
      }
      if (data.type === COMMAND_TYPES.PARAMS_WITHOUT_CONFIG) {
        delete data.jsonSchema;
      }

      if (!data.parameters) {
        data.parameters = false;
      }
      if (!data.mandatory) {
        data.mandatory = false;
      }
      if (isEditing) {
        await putCommandTrigger({ id: dialogId, body: data });
      } else {
        await postCommandTrigger(data);
      }
    },
    formValidation: async (formState, setCustomError, clearErrors, _, dialogId) => {
      if (!formState?.type?.value || formState?.type?.value === COMMAND_TYPES.NO_PARAMS) {
        setCustomError('payloadExample', CustomErrors.HIDE_FIELD);
        setCustomError('jsonSchema', CustomErrors.HIDE_FIELD);
      }

      if (formState?.type?.value === COMMAND_TYPES.PARAMS_WITHOUT_CONFIG) {
        setCustomError('jsonSchema', CustomErrors.HIDE_FIELD);
        clearErrors(['payloadExample']);
      }

      if (formState?.type?.value === COMMAND_TYPES.PARAMS_WITH_CONFIG) {
        clearErrors(['payloadExample']);
        clearErrors(['jsonSchema']);
      }

      const isEditing = typeof dialogId === 'string';
      const isCommandUniqueBody = {
        ...((formState.id && { id: formState.id || dialogId }) || {}),
        ...((formState.name && { name: formState.name }) || {}),
      };

      let isNameUnique: boolean | undefined = false;
      let isIdUnique: boolean | undefined = false;

      if (
        Object.keys(isCommandUniqueBody).length === 0 ||
        (isEditing && data?.data.items.find((t) => t.id === dialogId && t.name === isCommandUniqueBody.name))
      ) {
        if (isCommandUniqueBody.id) {
          clearErrors(['id']);
          clearErrors(['root.id']);
        }

        if (isCommandUniqueBody.name) {
          clearErrors(['name']);
          clearErrors(['root.name']);
        }
        return;
      }

      const isCommandUniqueRes = await isCommandUnique({ body: isCommandUniqueBody, isEditing });

      if (
        (
          isCommandUniqueRes as {
            error: FetchBaseQueryError | SerializedError;
          }
        ).error
      ) {
        // TODO - Handle the possible error
        return;
      }

      isNameUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isCommandUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.name
      );
      isIdUnique = [true, false].find(
        (bool) =>
          bool ===
          (
            isCommandUniqueRes as {
              data: ApiResult<CommonIsEntityUniqueRes>;
            }
          ).data?.data.id
      );
      isNameUnique = (isNameUnique === undefined && true) || isNameUnique;
      isIdUnique = (isIdUnique === undefined && true) || isIdUnique;

      if (!isEditing && !isIdUnique) {
        setCustomError('id', CustomErrors.UNIQUE);
        setCustomError('root.id', CustomErrors.UNIQUE);
      }

      if (!isEditing && isIdUnique) {
        clearErrors(['id']);
        clearErrors(['root.id']);
      }

      if (!isNameUnique) {
        setCustomError('name', CustomErrors.UNIQUE);
        setCustomError('root.name', CustomErrors.UNIQUE);
      }

      if (isNameUnique) {
        clearErrors(['name']);
        clearErrors(['root.name']);
      }
    },
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t('command'),
    message: (extra: any) => t('deleteMessage', { entity: t('command'), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteCommandTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useCommandsColumns(openDialog, confirm);

  return (
    <>
      <CommandsTable
        title={t(CHANNELS.COMMANDS)}
        data={data?.data.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('command')}
        onResetStateButtonClick={openDialog}
        exportData={true}
        selection={true}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
