import { Column } from '@material-table/core';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeviceIdentityRes } from './typings';
import dayjs from 'dayjs';
import ReleatedEntityLabel from '@/components/Shared/Chips/ReleatedEntityChip';
import { useGetTelemetryEntriesQuery } from '@/redux/api/system/telemetryApiSlice';
import { useGetConsumablesQuery } from '@/redux/api/system/consumablesApiSlice';
import { useGetAlarmsQuery } from '@/redux/api/system/alarmsApiSlice';
import { TelemetryRes } from '@/components/OneTools/Channels/Telemetry/typings';
import { AlarmRes } from '@/components/OneTools/Channels/Alarms/typings';
import { useGetBrandsQuery } from '@/redux/api/system/brandsApiSlice';
import { useNavigate } from 'react-router';
import { useGetEventsQuery } from '@/redux/api/system/eventsApiSlice';
import { EventRes } from '@/components/OneTools/Channels/Events/typings';
import { CommandRes } from '@/components/OneTools/Channels/Commands/typings';
import { useGetCommandsQuery } from '@/redux/api/system/commandsApiSlice';
import CulliganChip from '@/components/Shared/Chips/CulliganChip';
import Check from '@mui/icons-material/Check';
import { Clear } from '@mui/icons-material';
import ExpandableField from '@/components/Shared/ExpandableField';
import { CHANNELS, ECOSYSTEM, ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';
import { useGetConfigsQuery } from '@/redux/api/system/configsApiSlice';
import { ConfigRes } from '@/components/OneTools/Channels/Configs/typings';
import { BrandRes } from '../../Brands/typings';
import { ConsumableRes } from '@/components/OneTools/Channels/Consumables/typings';
import { useGetDevicesQuery } from '@/redux/api/fleet/devicesApiSlice';
import { Device } from '@/components/Fleet/Devices/DevicesPanel/typings';

const MAX_RELATED_ITEMS = 3;

export function useDeviceIdentityColumns(
  onEditClick: Function,
  onDeleteClick: Function,
  appEnv: string | undefined
): Array<Column<DeviceIdentityRes>> {
  const { t } = useTranslation();
  const columns_array: Array<Column<DeviceIdentityRes>> = [];
  const navigate = useNavigate();

  const { data: allDevices } = useGetDevicesQuery();

  // Brands
  const { data: brands, isLoading: isLoadingBrands } = useGetBrandsQuery();

  // Telemetry
  const { data: telemetry, isLoading: isLoadingTelemetry } = useGetTelemetryEntriesQuery();

  // Consumables
  const { data: allConsumables, isLoading: isLoadingConsumables } = useGetConsumablesQuery();

  // Alarms
  const { data: alarms, isLoading: isLoadingAlarms } = useGetAlarmsQuery();

  // Events
  const { data: allEvents, isLoading: isLoadingEvents } = useGetEventsQuery();

  // Commands
  const { data: allCommands, isLoading: isLoadingCommands } = useGetCommandsQuery();

  // Configs
  const { data: allConfigs, isLoading: isLoadingConfigs } = useGetConfigsQuery();

  return columns_array.concat([
    {
      title: t('name'),
      width: '15%',
      field: 'name',
    },
    {
      title: t('code'),
      width: '15%',
      field: 'id',
    },
    {
      title: t('baseProtocolVersion'),
      width: '15%',
      field: 'baseProtocolVersion',
    },
    {
      title: t('description'),
      width: '15%',
      field: 'description',
    },
    {
      title: t('connectivity'),
      width: '15%',
      field: 'connectivity',
      render: (deviceIdentity) => {
        return deviceIdentity.connectivity.join(', ');
      },
      exportTransformer: (deviceIdentity) =>
        (deviceIdentity.connectivity as string[])?.join
          ? (deviceIdentity.connectivity as string[])?.join(', ')
          : deviceIdentity.connectivity,
      sorting: false,
    },
    {
      title: t(ECOSYSTEM.BRANDS),
      width: '15%',
      field: ECOSYSTEM.BRANDS,
      customFilterAndSearch: (filter, deviceIdentity) => {
        const brandsData = brands?.data.items.filter((brand: BrandRes) => deviceIdentity?.brands?.includes(brand.id));
        return brandsData?.some((brand) => brand.name.startsWith(filter)) || false;
      },
      render: (deviceIdentity) => {
        if (!deviceIdentity.brands || deviceIdentity.brands.length === 0 || !Array.isArray(deviceIdentity.brands)) {
          return;
        }
        const brandsData =
          brands?.data.items.filter((brand: BrandRes) => deviceIdentity.brands?.includes(brand.id)) || [];
        return (
          !isLoadingBrands &&
          brandsData &&
          (brandsData.length === 0 ? (
            <ReleatedEntityLabel severity="warning" />
          ) : (
            <ExpandableField
              content={brandsData.map((brand) => (
                <ReleatedEntityLabel
                  key={brand.id}
                  text={brand.name}
                  severity="warning"
                  onEntityClick={() =>
                    navigate(`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.ECOSYSTEM}#brands`, {
                      state: {
                        searchText: brand.name,
                        highlight: {
                          refField: 'id',
                          matchValue: brand.id,
                        },
                      },
                    })
                  }
                />
              ))}
              maxItems={MAX_RELATED_ITEMS}
              ItemsContainer={Box}
            />
          ))
        );
      },
      exportTransformer: (deviceIdentity) =>
        (deviceIdentity.brands as string[])?.join
          ? (deviceIdentity.brands as string[])?.join(', ')
          : deviceIdentity.brands,
      sorting: false,
    },
    {
      title: t('tier'),
      width: '15%',
      field: 'tier',
    },
    {
      title: t('type'),
      width: '15%',
      field: 'type',
    },
    {
      title: t('alwaysConnected'),
      width: '15%',
      field: 'alwaysConnected',
      render: (deviceIdentity) => <Typography>{deviceIdentity.alwaysConnected ? t('yes') : t('no')}</Typography>,
    },
    {
      title: t('operatingModes'),
      width: '15%',
      field: 'supportedOperatingModes',
      render: (deviceIdentity) => {
        return deviceIdentity?.operatingModes?.map((om) => <CulliganChip key={om} label={t(om)}></CulliganChip>);
      },
      exportTransformer: (deviceIdentity) =>
        (deviceIdentity.operatingModes as string[])?.join
          ? (deviceIdentity.operatingModes as string[])?.join(', ')
          : deviceIdentity.operatingModes,
    },
    {
      title: t('powerProfiles'),
      width: '15%',
      field: 'supportedOperatingModes',
      render: (deviceIdentity) => {
        return deviceIdentity?.powerProfiles?.map((powerProfile) => (
          <CulliganChip key={powerProfile} label={t(powerProfile)}></CulliganChip>
        ));
      },
      exportTransformer: (deviceIdentity) =>
        (deviceIdentity.powerProfiles as string[])?.join
          ? (deviceIdentity.powerProfiles as string[])?.join(', ')
          : deviceIdentity.powerProfiles,
    },
    {
      title: t('image'),
      width: '15%',
      field: 'image',
      customFilterAndSearch: (filter: any, deviceIdentity) => {
        const image = deviceIdentity.image ? 'Set' : 'Unset';
        return image.startsWith(filter);
      },
      render: (brand) =>
        brand.image ? (
          <CulliganChip icon={<Check color="success" />} label={t('set')} />
        ) : (
          <CulliganChip icon={<Clear color="error" />} label={t('unset')} />
        ),
    },
    {
      title: t(CHANNELS.TELEMETRY),
      width: '15%',
      field: CHANNELS.TELEMETRY,
      customFilterAndSearch: (filter, deviceIdentity) => {
        const telemetryEntries = telemetry?.data.items.filter((t: TelemetryRes) =>
          deviceIdentity.telemetry?.includes(t.id)
        );

        return telemetryEntries?.some((telemetryEntry) => telemetryEntry.name.startsWith(filter)) || false;
      },
      render: (deviceIdentity) => {
        if (
          !deviceIdentity.telemetry ||
          deviceIdentity.telemetry.length === 0 ||
          !Array.isArray(deviceIdentity.telemetry)
        ) {
          return;
        }
        const telemetryEntries = telemetry?.data.items.filter((t: TelemetryRes) =>
          deviceIdentity.telemetry?.includes(t.id)
        );
        return (
          !isLoadingTelemetry &&
          telemetryEntries &&
          (telemetryEntries.length === 0 ? (
            <ReleatedEntityLabel severity="warning" />
          ) : (
            <ExpandableField
              content={telemetryEntries.map((telemetry) => (
                <ReleatedEntityLabel
                  key={telemetry.id}
                  severity="warning"
                  text={telemetry.name}
                  onEntityClick={() =>
                    navigate(`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#telemetry`, {
                      state: {
                        searchText: telemetry.name,
                        highlight: {
                          refField: 'id',
                          matchValue: telemetry.id,
                        },
                      },
                    })
                  }
                />
              ))}
              maxItems={MAX_RELATED_ITEMS}
              ItemsContainer={Box}
            />
          ))
        );
      },
      exportTransformer: (deviceIdentity) =>
        (deviceIdentity.telemetry as string[])?.join
          ? (deviceIdentity.telemetry as string[])?.join(', ')
          : deviceIdentity.telemetry,
      sorting: false,
    },
    {
      title: t(CHANNELS.ALARMS),
      width: '15%',
      field: CHANNELS.ALARMS,
      customFilterAndSearch: (filter, deviceIdentity) => {
        const alarmsEntries = alarms?.data.items.filter((t: AlarmRes) => deviceIdentity?.alarms?.includes(t.id));

        return alarmsEntries?.some((alarmsEntry) => alarmsEntry.name.startsWith(filter)) || false;
      },
      render: (deviceIdentity) => {
        if (!deviceIdentity.alarms || deviceIdentity.alarms.length === 0) {
          return;
        }
        let _alarms = alarms?.data.items || [];
        _alarms = _alarms.filter((aa: AlarmRes) => deviceIdentity.alarms?.includes(aa.id));
        return (
          !isLoadingAlarms &&
          _alarms &&
          (_alarms.length === 0 ? (
            <ReleatedEntityLabel severity="warning" />
          ) : (
            <ExpandableField
              content={_alarms.map((alarm) => (
                <ReleatedEntityLabel
                  key={alarm.id}
                  severity="warning"
                  text={alarm.name}
                  onEntityClick={() =>
                    navigate(`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#alarms`, {
                      state: {
                        searchText: alarm.name,
                        highlight: {
                          refField: 'id',
                          matchValue: alarm.id,
                        },
                      },
                    })
                  }
                />
              ))}
              maxItems={MAX_RELATED_ITEMS}
              ItemsContainer={Box}
            />
          ))
        );
      },
      exportTransformer: (deviceIdentity) =>
        (deviceIdentity.alarms as string[])?.join
          ? (deviceIdentity.alarms as string[])?.join(', ')
          : deviceIdentity.alarms,
      sorting: false,
    },
    {
      title: t(CHANNELS.CONFIGS),
      width: '15%',
      field: CHANNELS.CONFIGS,
      customFilterAndSearch: (filter, deviceIdentity) => {
        const configsEntries = allConfigs?.data.items.filter((t: ConfigRes) => deviceIdentity?.configs?.includes(t.id));

        return configsEntries?.some((configsEntry) => configsEntry.name.startsWith(filter)) || false;
      },
      render: (deviceIdentity) => {
        if (!deviceIdentity.configs || deviceIdentity.configs.length === 0) {
          return;
        }
        const configs = allConfigs?.data.items.filter((t: ConfigRes) => deviceIdentity.configs?.includes(t.id));
        return (
          !isLoadingConfigs &&
          configs &&
          (configs.length === 0 ? (
            <ReleatedEntityLabel severity="warning" />
          ) : (
            <ExpandableField
              content={configs.map((config) => (
                <ReleatedEntityLabel
                  key={config.id}
                  severity="warning"
                  text={config.name}
                  onEntityClick={() =>
                    navigate(`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#configs`, {
                      state: {
                        searchText: config.name,
                        highlight: {
                          refField: 'id',
                          matchValue: config.id,
                        },
                      },
                    })
                  }
                />
              ))}
              maxItems={MAX_RELATED_ITEMS}
              ItemsContainer={Box}
            />
          ))
        );
      },
      exportTransformer: (deviceIdentity) =>
        (deviceIdentity.configs as string[])?.join
          ? (deviceIdentity.configs as string[])?.join(', ')
          : deviceIdentity.configs,
      sorting: false,
    },
    {
      title: t(CHANNELS.CONSUMABLES),
      width: '15%',
      field: CHANNELS.CONSUMABLES,
      customFilterAndSearch: (filter, deviceIdentity) => {
        const consumablesEntries = allConsumables?.data.items.filter((t: ConsumableRes) =>
          deviceIdentity?.consumables?.includes(t.id)
        );

        return consumablesEntries?.some((consumablesEntry) => consumablesEntry.name.startsWith(filter)) || false;
      },
      render: (deviceIdentity) => {
        if (
          !deviceIdentity.consumables ||
          deviceIdentity.consumables.length === 0 ||
          !Array.isArray(deviceIdentity.consumables)
        ) {
          return;
        }
        const consumables = (deviceIdentity.consumables || []).flatMap(
          (c) => allConsumables?.data.items.find((r) => r.id === c) || []
        );

        return (
          !isLoadingConsumables &&
          consumables &&
          (consumables.length === 0 ? (
            <ReleatedEntityLabel severity="warning" />
          ) : (
            <ExpandableField
              content={consumables.map((consumable) => (
                <ReleatedEntityLabel
                  key={consumable.id + Math.random()}
                  severity="warning"
                  text={consumable.name}
                  onEntityClick={() =>
                    navigate(`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#consumables`, {
                      state: {
                        searchText: consumable.name,
                        highlight: {
                          refField: 'id',
                          matchValue: consumable.id,
                        },
                      },
                    })
                  }
                />
              ))}
              maxItems={MAX_RELATED_ITEMS}
              ItemsContainer={Box}
            />
          ))
        );
      },
      exportTransformer: (deviceIdentity) =>
        (deviceIdentity.consumables as string[])?.join
          ? (deviceIdentity.consumables as string[])?.join(', ')
          : deviceIdentity.consumables,
      sorting: false,
    },
    {
      title: t(CHANNELS.EVENTS),
      width: '15%',
      field: CHANNELS.EVENTS,
      customFilterAndSearch: (filter, deviceIdentity) => {
        const eventsEntries = allEvents?.data.items.filter((t: EventRes) => deviceIdentity?.events?.includes(t.id));

        return eventsEntries?.some((eventsEntry) => eventsEntry.name.startsWith(filter)) || false;
      },
      render: (deviceIdentity) => {
        if (!deviceIdentity.events || deviceIdentity.events.length === 0) {
          return;
        }
        const events = allEvents?.data.items.filter((t: EventRes) => deviceIdentity.events?.includes(t.id));
        return (
          !isLoadingEvents &&
          events &&
          (events.length === 0 ? (
            <ReleatedEntityLabel severity="warning" />
          ) : (
            <ExpandableField
              content={events.map((event) => (
                <ReleatedEntityLabel
                  key={event.id}
                  severity="warning"
                  text={event.name}
                  onEntityClick={() =>
                    navigate(`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#events`, {
                      state: {
                        searchText: event.name,
                        highlight: {
                          refField: 'id',
                          matchValue: event.id,
                        },
                      },
                    })
                  }
                />
              ))}
              maxItems={MAX_RELATED_ITEMS}
              ItemsContainer={Box}
            />
          ))
        );
      },
      exportTransformer: (deviceIdentity) =>
        (deviceIdentity.events as string[])?.join
          ? (deviceIdentity.events as string[])?.join(', ')
          : deviceIdentity.events,
      sorting: false,
    },
    {
      title: t(CHANNELS.COMMANDS),
      width: '15%',
      field: CHANNELS.COMMANDS,
      customFilterAndSearch: (filter, deviceIdentity) => {
        const commandsEntries = allCommands?.data.items.filter((t: CommandRes) =>
          deviceIdentity?.commands?.includes(t.id)
        );

        return commandsEntries?.some((commandsEntry) => commandsEntry.name.startsWith(filter)) || false;
      },
      render: (deviceIdentity) => {
        if (!deviceIdentity.commands || deviceIdentity.commands.length === 0) {
          return;
        }
        const commands = allCommands?.data.items.filter((t: CommandRes) => deviceIdentity.commands?.includes(t.id));
        return (
          !isLoadingCommands &&
          commands &&
          (commands.length === 0 ? (
            <ReleatedEntityLabel severity="warning" />
          ) : (
            <ExpandableField
              content={commands.map((command) => (
                <ReleatedEntityLabel
                  key={command.id}
                  severity="warning"
                  text={command.name}
                  onEntityClick={() =>
                    navigate(`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#commands`, {
                      state: {
                        searchText: command.name,
                        highlight: {
                          refField: 'id',
                          matchValue: command.id,
                        },
                      },
                    })
                  }
                />
              ))}
              maxItems={MAX_RELATED_ITEMS}
              ItemsContainer={Box}
            />
          ))
        );
      },
      exportTransformer: (deviceIdentity) =>
        (deviceIdentity.commands as string[])?.join
          ? (deviceIdentity.commands as string[])?.join(', ')
          : deviceIdentity.commands,
      sorting: false,
    },
    {
      title: t('creationDate'),
      width: '15%',
      field: 'createdAt',
      render: (deviceIdentity) => dayjs(deviceIdentity.createdAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('lastUpdated'),
      width: '15%',
      field: 'updatedAt',
      render: (deviceIdentity) => dayjs(deviceIdentity.updatedAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('actions'),
      width: '11%',
      render: (deviceIdentity) => {
        const count = allDevices?.data.items.filter((device: Device) => device.model === deviceIdentity.id).length;
        return (
          <Box display="flex" justifyContent="flex-start" gap="0.5rem">
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/${ROUTE_SECTION.ONETOOL}/ecosystem/device-identity/${deviceIdentity.id}`)}
            >
              {t('view')}
            </Button>
            <Button variant="outlined" color="primary" onClick={() => onEditClick(deviceIdentity.id)}>
              {t('edit')}
            </Button>
            {(appEnv === 'dev' || (appEnv !== 'dev' && count === 0)) && (
              <Button
                variant="outlined"
                color="error"
                onClick={() =>
                  onDeleteClick({
                    id: deviceIdentity.id,
                    name: deviceIdentity.name,
                  })
                }
              >
                {t('delete')}
              </Button>
            )}
          </Box>
        );
      },
      sorting: false,
      searchable: false,
    },
  ]);
}
